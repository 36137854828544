import axios from 'axios';

const environments = {
    DIRECTIONALCORP: {
        directionalcorp: {
            backend_helga: "https://directionalcorp.helgasys.com", 
            client_secret: "crYi03ogbygAOonnwcXmVtTpLvZ5IOVcqTOi3Qab", 
            client_id: 2, 
            app_id: 'dtcc61925b0325dc775e468b2a2ea294d1e3c44c358b935f3ccf235a77a67d9d7dd',
            oficina_id: 3,
            servicio_id: 6,
            forma_pago_id: 1,
            tipo_cliente_id: 1,
            medio_pago_id: 2,
            app_name: 'Directional Corp',
            bodega: 'orlando'
        },
        iec: {
            backend_helga: "https://iec.helgasys.com", 
            client_secret: "o7lxe7F9G9RwrK1wuymrEJGIqT4Cm5zImyRqCoMp", 
            client_id: 2, 
            app_id: 'dc90ddfce2540c43a215ab73004d7e29970512bd9a25085dd1977c673e9c9f7482',
            oficina_id: 22,
            servicio_id: 27,
            forma_pago_id: 1,
            tipo_cliente_id: 6,
            medio_pago_id: 8,
            app_name: 'IEC',
            bodega: 'miami'
        }
    },
    DEFAULT: {
        HELGA: {
            backend_helga: process.env.VUE_APP_URL_BACKEND_HELGA,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            client_id: process.env.VUE_APP_CLIENT_ID,
            app_id: process.env.VUE_APP_ID,
            oficina_id: process.env.VUE_APP_OFICINA_ID, 
            servicio_id: process.env.VUE_APP_SERVICIO_ID,
            forma_pago_id: process.env.VUE_APP_FORMA_PAGO_ID,
            tipo_cliente_id: process.env.VUE_APP_TIPO_CLIENTE_ID,
            medio_pago_id: process.env.VUE_APP_MEDIO_PAGO_ID,
            app_name: process.env.VUE_APP_NAME
        }
    }
};

const showBodega = () =>{
    let show = false;
    Object.entries(environments).forEach(([key]) => {
      if (key == process.env.VUE_APP_NAME){
          show = true;
      }
    });
    return show;
}

const apiGlobal = () => {
    return axios.create({
        baseUrl: environments.default.backend_helga,
    })
};

const valdiarApi = () => {
    let ambientes = null
    Object.entries(environments).forEach(([key, value]) => {
      if (key == process.env.VUE_APP_NAME){
        ambientes = value
      }
    });
    if (ambientes != null){
        return ambientes;
    }else{
        return environments.DEFAULT;
    }
}

const getSelectedEnvironment = () => {
   const currentEnvironment = localStorage.getItem('bodega');
    let currentSelectedEnvironment = environments.DEFAULT.HELGA;
    const show = showBodega();
    if(show){
       let objetos = valdiarApi();
       Object.values(objetos).forEach(value => {
        if (value.bodega == currentEnvironment){
            currentSelectedEnvironment = value;
        }
      });
    }
    return currentSelectedEnvironment;
}

const api = () => {
    return axios.create({
        baseURL: getSelectedEnvironment().backend_helga,
    })
}

export {
    apiGlobal,
    environments,
    api,
    getSelectedEnvironment,
    showBodega,
    valdiarApi
};
