<template>
  <div class="container vertical-center login">
    <div class="inner-block">
      <div class="vue-tempalte">
        <form @submit.prevent="submit">
          <h3>Login</h3>

          <div class="form-group">
            <label>Correo electrónico</label>
            <input
              class="form-control"
              v-validate="'required'"
              :class="{ 'is-invalid': submitted && errors.has('username') }"
              id="username"
              name="username"
              type="email"
              v-model="username"
            />
            <span v-show="errors.has('username')" class="invalid-feedback"
              >Este campo es requerido</span
            >
          </div>

          <div class="form-group">
            <label>Contraseña</label>
            <input
              class="form-control"
              v-validate="'required'"
              :class="{ 'is-invalid': submitted && errors.has('password') }"
              id="password"
              name="password"
              type="password"
              v-model="password"
              required=""
            />
            <span v-show="errors.has('password')" class="invalid-feedback"
              >Este campo es requerido</span
            >
          </div>
          <div class="form-group" v-if="ambiente">
            <label for="">Bodega - ORLANDO</label>
            <!-- <select
              class="form-control"
              v-validate="{ required: this.showBodega }"
              name="selbodega"
              id="selbodega"
              @change="changeBodega()"
              v-model="bodega"
            >
              <option
                selected="selected"
                value="orlando"
                >ORLANDO</option
              >
              <option
                v-for="item in bodegaOptions"
                :key="item.text"
                selected="selected"
                value="orlando"
                >{{ item.text }}</option
              >
            </select> -->
          </div>

          <button type="submit" class="btn btn-dark btn-block">
            Iniciar Sesión
          </button>

          <p class="forgot-password text-right mt-2 mb-4">
            <router-link to="/recuperar-contrasena"
              >¿Olvidó la contraseña?</router-link
            >
          </p>
        </form>
        <Modal v-if="showModal">
          <h3 slot="body" class="modal-body">
            {{ responseMessage }}
          </h3>

          <div slot="footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="closeModal()"
            >
              Aceptar
            </button>
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "./Modal.vue";
import { getSelectedEnvironment, api, showBodega } from "../plugins/api_conect";

export default {
  name: "Login",
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.checked = false;
      this.submitted = false;
    },
    submit() {
      this.submitted = true;
      this.responseMessage = "Espere un momento, cargando";
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.showModal = true;
          const currentEnvironment = getSelectedEnvironment();

          let datos = {
            grant_type: "password",
            client_id: currentEnvironment.client_id,
            client_secret: currentEnvironment.client_secret,
            username: this.username,
            password: this.password,
            scope: "",
          };

          // const url = '/oauth/token';
          //SE REMPLAZA FETCH POR AXIOS
          api()
            .post("/oauth/token", datos, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            })
            .then((response) => {
              const { data } = response;

              this.$store.commit("update_auth_tokens", data);

              api()
                .get("/api/user", {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: data.token_type + " " + data.access_token,
                  },
                })
                .then((resp) => {
                  localStorage.user_id = resp.data["datos"]["usuario"]["id"];
                  localStorage.cliente_id = resp.data["datos"]["cliente"]["id"];
                  localStorage.user_name =
                    resp.data["datos"]["usuario"]["name"];

                  localStorage.setItem(
                    "datosUsuario",
                    JSON.stringify(resp.data["datos"]["usuario"])
                  );
                  localStorage.setItem(
                    "datosCliente",
                    JSON.stringify(resp.data["datos"]["cliente"])
                  );

                  localStorage.setItem(
                    "user_name",
                    resp.data["datos"]["usuario"]["name"]
                  );

                  this.$cookies.set("clienteId", "true");

                  this.showModal = false;

                  window.location.href = `${process.env.VUE_APP_URL}/rastrear-pedido`;
                })
                .catch(() => {
                  this.$swal(
                    "Error",
                    "Ocurrio un error, no se puede iniciar sesión",
                    "error"
                  );
                  this.checked = true;
                });
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                this.$swal(
                  "Error",
                  "Usuario y/o contraseña incorrectos",
                  "error"
                );
                this.checked = true;
              } else {
                this.$swal(
                  "Error",
                  "Ocurrio un error, no se puede iniciar sesión",
                  "error"
                );
                this.checked = true;
              }
            });
          
        }else{
           this.$swal(
                    "Error",
                    "Por favor llene todos los campos",
                    "error"
                  );
        }
      })
    },
    changeBodega() {
      localStorage.bodega = this.bodega;
    },
  },
  data() {
    return {
      submitted: false,
      username: "",
      password: "",
      showModal: false,
      selected: '',
      responseMessage: "",
      access_token: "",
      refresh_token: "",
      token_type: "",
      ambiente: showBodega(),
      bodega: "orlando",
      bodegaOptions: [
        { text: "ORLANDO", value: "orlando" },
      ],
    };
  },
  mounted: function() {
    if (localStorage.access_token) {
      this.access_token = localStorage.access_token;
    }
    if (localStorage.refresh_token) {
      this.refresh_token = localStorage.refresh_token;
    }
    if (localStorage.token_type) {
      this.token_type = localStorage.token_type;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  min-height: 80vh;
  display: flex;
  font-weight: 400;
}

body,
html,
.App,
.vue-tempalte,
.vertical-center {
  width: 100%;
  height: 100%;
}

.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;
}

.inner-block {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.vertical-center .form-control:focus {
  border-color: #b4141d;
  box-shadow: none;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .inner-block {
    width: auto;
  }
}

label {
  font-weight: 500;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7a7a7a;
  margin: 0;
}

.forgot-password a {
  color: #b4141d;
}
</style>
