<template>
  <div class="vertical-center resetpassword">
    <div class="inner-block">
      <div class="vue-tempalte">
        <form @submit.prevent="submit">
          <h3>Cambiar Contraseña</h3>

          <div class="form-group">
            <label>Email *</label>
            <input
              v-validate="'required'"
              v-model="userData.email"
              name="email"
              type="email"
              :class="{ 'is-danger': errors.has('email') }"
              placeholder="Email"
              ref="email"
              class="form-control"
              required
            />
            <span v-show="errors.has('email')" class="small help text-danger"
              >No es un email válido</span
            >
          </div>
          <div class="form-group">
            <label>Contraseña nueva *</label>
            <input
              class="form-control"
              v-validate="'required'"
              v-model="userData.password"
              name="password"
              type="password"
              placeholder="Contraseña nueva"
              ref="password"
              required
            />
          </div>
          <div class="form-group">
            <label>Confirmar Contraseña *</label>
            <input
              class="form-control"
              v-validate="'required'"
              v-model="userData.password_confirmation"
              name="password_confirmation"
              type="password"
              placeholder="Confirmar contraseña"
              ref="password_confirmation"
              required
            />
          </div>
          <button type="submit" class="btn btn-dark btn-block">Enviar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "../plugins/api_conect";

export default {
  name: "ResetPassword",
  methods: {
    submit: function() {
      
      this.$validator.validate().then(valid => {
        if (valid) {
          const datos = {
            email: this.userData.email,
            token: this.userData.token,
            password: this.userData.password,
            password_confirmation: this.userData.password_confirmation
          };

          api().post(`/api/usuarios/password/reset`, datos, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          })
            .then(response => {
              const {data} = response;
                this.userData = {};
                this.$swal("Proceso completo", data["msg"], "success");
                window.location.href = `${process.env.VUE_APP_URL}/`;
            }).catch((error) => {
              if (error.response.status == 422){
                this.$swal("Error", error.response.data.errors.password[0], "error");
              } else {
                this.$swal("Error", error.response.data["msg"], "error");
              }
            });
        }
      });
    },
  },
  data() {
    return {
      userData: {
        email: "",
        token: "",
        password: "",
        password_confirmation: ""
      }
    };
  },
  mounted(){
    let url_string = window.location.href;
    let url = new URL(url_string);
    let token = url.searchParams.get("token");
    this.userData.token = token;
  }
};
</script>

<style scoped>
.resetpassword {
  min-height: 80vh;
  display: flex;
  font-weight: 400;
}

body,
html,
.App,
.vue-tempalte,
.vertical-center {
  width: 100%;
  height: 100%;
}

.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;
}

.inner-block {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

@media (max-width: 768px) {
  .inner-block {
    width: auto;
  }
}

.vertical-center .form-control:focus {
  border-color: #b4141d;
  box-shadow: none;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label {
  font-weight: 500;
}
</style>