<template>
    <div v-show="logueado" id="miperfil" class="container">
        <form @submit.prevent="submit">
            <h2>Mi Perfil</h2>
            <div class="separador">
                <h3>Datos Generales</h3>
                <br />
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Tipo de cuenta *</label>
                        <select class="form-control" name="tipo_cuenta" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('tipo_cuenta') }" v-model="tipo_de_cuenta_id" ref="tipo_de_cuenta_id">
                          <option selected="selected" value="1">Personal</option>
                          <option value="2">Empresarial</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Primer nombre *</label>
                        <input name="primer_nombre" v-model="userData.primer_nombre" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('primer_nombre') }" class="form-control" placeholder="Primer nombre *" type="text" />
                        <span v-show="errors.has('primer_nombre')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
    
                    <div class="form-group col-md-6">
                        <label>Primer apellido *</label>
                        <input name="primer_apellido" v-model="userData.primer_apellido" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('primer_apellido') }" class="form-control" placeholder="Primer apellido *" type="text" />
                        <span v-show="errors.has('primer_apellido')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Número de identificación *</label>
                        <input name="numero_de_identificacion" v-model="userData.numero_de_identificacion" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('numero_de_identificacion') }" class="form-control" placeholder="Nit o numero de identificacion *"
                            type="text" />
                        <span v-show="errors.has('numero_de_identificacion')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Género *</label>
                        <select class="form-control" v-model="userData.genero_id" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('genero_id') }" name="genero_id">
                          <option selected="selected" value>Género</option>
                          <option value="1">Masculino</option>
                          <option value="2">Femenino</option>
                        </select>
                        <span v-show="errors.has('genero_id')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="mt-1 mb-0 ml-1">Teléfono celular *</label>
                        <input name="telefono_celular" v-model="userData.telefono_celular" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('telefono_celular') }" class="form-control" placeholder="Teléfono celular *" type="text" />
                        <span v-show="errors.has('telefono_celular')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Télefono fijo</label>
                        <input name="telefono_fijo" v-model="userData.telefono_fijo" class="form-control" placeholder="Teléfono fijo" type="text" />
                    </div>
                    <div class="form-group col-md-6">
                        <label>Dirección *</label>
                        <input name="direccion" v-model="userData.direccion" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('direccion') }" class="form-control" placeholder="Dirección*" type="text" />
                        <span v-show="errors.has('direccion')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Edificio / Barrio / Apto u Oficina *</label>
                        <input name="barrio" v-model="userData.barrio" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('barrio') }" class="form-control" placeholder="Edificio / Barrio / Apto u Oficina" type="text" />
                        <span v-show="errors.has('barrio')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                    <div class="form-group col-md-6">
                        <label>País *</label>
                        <select class="form-control" v-model="selectPais" v-on:change="getEstados(selectPais)" name="ePais" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('ePais') }">
                          <option v-for="pais in paises" :key="pais.codigo" :value="pais.codigo">{{pais.nombre}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Departamento *</label>
                        <select class="form-control" v-model="selectEstado" v-on:change="getCiudades(selectEstado)" name="eEstado" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('eEstado') }">
                          <option
                            v-for="estado in estados"
                            :key="estado.id"
                            :value="estado.id"
                          >{{estado.nombre}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Ciudad *</label>
                        <select class="form-control" v-model="selectCiudad" name="eCiudad" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('eCiudad') }">
                          <option
                            v-for="ciudad in ciudades"
                            :key="ciudad.id"
                            :value="ciudad.id"
                          >{{ciudad.nombre}}</option>
                        </select>
                    </div>
    
                    <div class="form-group col-md-6">
                        <label>Código Postal</label>
                        <div class="row col-md-12">
                            <input name="codigo_postal" v-model="codigo_postal" class="form-control col-md-9" placeholder type="text" />
                            <a class="form-control btn btn-dark fondo-rojo col-md-3" href="#" v-on:click="buscarCodigo" v-if="codigo_postal.length > 1">
                            Buscar
                            <b-icon icon="search" aria-hidden="true"></b-icon>
                          </a>
                        </div>
                    </div>
                </div>
    
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <h3>Información de Seguridad</h3>
                    </div>
    
                    <div class="form-group col-md-6">
                        <label>Email *</label>
                        <input class="form-control" v-validate="'required'" v-model="userData.email" name="email" type="email" :class="{'is-danger': errors.has('email')}" placeholder="Email" ref="email" />
                        <span v-show="errors.has('email')" class="small help text-danger">No es un email válido</span>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Confirmación email *</label>
                        <input class="form-control" v-validate="'required|confirmed:email'" v-model="confirmarEmail" name="email_confirmation" type="email" :class="{'is-danger': errors.has('email_confirmation')}" placeholder="Confirmar email" data-vv-as="email" />
                        <span v-show="errors.has('email_confirmation')" class="small help text-danger">El email no coincide</span>
                    </div>
                </div>
    
                <p>los campos (*) son obligatorios</p>
    
                <div class="form-row">
                    <div class="form-group col-md-4"></div>
                    <div class="form-group col-md-4">
                        <button class="btn btn-lg btn-block btn-dark fondo-rojo">
                          <b-icon icon="arrow-right-square-fill" aria-hidden="true"></b-icon>Registrar
                        </button>
                    </div>
                    <div class="form-group col-md-4"></div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { getSelectedEnvironment, api } from "../plugins/api_conect";
const currentEnvironment = getSelectedEnvironment();
export default {
    computed: {
        logueado() {
            return this.$store.getters.loggedIn;
        }
    },
    methods: {
        buscarCodigo() {
            this.responseMessage = "Espere un momento...";
            this.showModal = true;
            api().get(`/api/casillero/codigospostales/${this.codigo_postal}/${this.selectPais}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    }
                })
                .then((response) => {
                    const { data } = response;
                    const indexPais = this.paises.findIndex(
                        x => x.codigo === data["datos"]["pais"][0]["codigo"]
                    );
                    this.selectPais = this.paises[indexPais].codigo;

                    this.getEstados(this.selectPais);

                    const indexEstado = this.estados.findIndex(
                        x => x.id === data["datos"]["dpto"]["id"]
                    );
                    this.selectEstado = this.estados[indexEstado].id;

                    const indexCiudad = this.ciudades.findIndex(
                        x => x.id === data["datos"]["ciudad"]["id"]
                    );
                    this.selectCiudad = this.ciudades[indexCiudad].id;

                    this.userData.codigo_postal_id = data["datos"]["postal"][0]["id"];

                    this.buscarPostal = true;
                }).catch(async (error) => {
                    this.checked = true;
                    if (error.response && error.response.status === 400) {
                        this.$swal("Error", "Este codigo postal no esta en este pais", "error");
                    } else if (error.response && error.response.status === 401) {
                        try {
                            await this.refreshToken();
                            this.buscarCodigo();
                        } catch (error) {
                            this.$swal("Error", error, "error");
                        }

                    } else {
                        if (error.response)
                            this.$swal("Error", error.response.data.message, "error");
                    }
                });
        },
        async refreshToken() {
            let tokenExpiryDate = this.$store.getters.tokensExpiry;
            const now = new Date();
            if (new Date(tokenExpiryDate) < now) {
                this.logout();
            } else {
                let datos = {
                    grant_type: "refresh_token",
                    client_id: currentEnvironment.client_id,
                    client_secret: currentEnvironment.client_secret,
                    refresh_token: localStorage.getItem('refresh_token')
                };
                try {
                    const response = await api().post(`/oauth/token`, datos, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*"
                        },
                    });
                    const { data } = response;
                    localStorage.access_token = data.access_token;
                    localStorage.refresh_token = data.refresh_token;
                    localStorage.token_type = data.token_type;

                    this.$store.commit("update_auth_tokens", data);
                } catch (error) {
                    this.$swal(
                        "Error",
                        "Ocurrio un error",
                        "error"
                    );
                }
            }
        },
        logout() {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("token_type");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_name");
            localStorage.removeItem("cliente_id");
            localStorage.removeItem("datosUsuario");
            localStorage.removeItem("datosCliente");
            localStorage.removeItem("tokensExpiry");

            this.$cookies.remove("clienteId");

            window.location.href = `${process.env.VUE_APP_URL}/`;
        },
        loadData() {
            var datosCliente = JSON.parse(localStorage.getItem("datosCliente"));

            this.tipo_de_cuenta_id = datosCliente["tipo_de_cuenta_id"];
            this.userData.primer_nombre = datosCliente["primer_nombre"];
            this.userData.primer_apellido = datosCliente["primer_apellido"];
            this.userData.numero_de_identificacion =
                datosCliente["numero_de_identificacion"];
            this.userData.barrio = datosCliente["barrio"] == null ? '' : datosCliente["barrio"];
            this.userData.email = datosCliente["email"];
            this.confirmarEmail = datosCliente["email"];
            this.userData.telefono_celular = datosCliente["telefono_celular"];
            this.userData.direccion = datosCliente["direccion"];
            this.pais = datosCliente["pais"];
            this.estado = datosCliente["departamento"];
            this.ciudad = datosCliente["ciudad"];
            this.userData.genero_id = datosCliente["genero_id"];

            if (datosCliente["segundo_nombre"] != null) {
                this.userData.segundo_nombre = datosCliente["segundo_nombre"];
            } else {
                this.userData.segundo_nombre = "";
            }
            if (datosCliente["segundo_apellido"] != null) {
                this.userData.segundo_apellido = datosCliente["segundo_apellido"];
            } else {
                this.userData.segundo_apellido = "";
            }
            if (datosCliente["telefono_fijo"] != null) {
                this.userData.telefono_fijo = datosCliente["telefono_fijo"];
            } else {
                this.userData.telefono_fijo = "";
            }
        },
        getPaises: function() {
            api().get(`/api/casillero/clientes/getCountries/${currentEnvironment.app_id}`)
                .then((response) => {
                    const { data } = response;
                    this.paises = data;

                    if (this.pais) {
                        const indexPais = this.paises.findIndex(
                            x => x.codigo === this.pais.codigo
                        );
                        this.selectPais = this.paises[indexPais].codigo;
                        this.getEstados(this.selectPais);
                    }
                });
        },
        getEstados: function(codigoPais) {
            api().get(`/api/casillero/clientes/getDepartaments/${codigoPais}/${currentEnvironment.app_id}`)
                .then(response => {
                    const { data } = response;
                    if (data.length > 0) {
                        this.estados = data;

                        if (this.estado) {
                            const indexEstado = this.estados.findIndex(
                                x => x.id === this.estado.id
                            );
                            this.selectEstado = this.estados[indexEstado].id;
                            this.getCiudades(this.selectEstado);
                        }
                    } else {
                        this.selectEstado = "";
                        this.selectCiudad = "";
                    }
                });
        },
        getCiudades: function(idEstado) {
            api().get(`/api/casillero/clientes/getCities/${idEstado}/${currentEnvironment.app_id}`)
                .then(response => {
                    const { data } = response;
                    if (data.length > 0) {
                        this.ciudades = data;

                        if (this.estado && this.buscarPostal != true) {
                            const indexCiudad = this.ciudades.findIndex(
                                x => x.id === this.ciudad.id
                            );
                            this.selectCiudad = this.ciudades[indexCiudad].id;
                        }
                    } else {
                        this.selectCiudad = "";
                    }
                });
        },
        closeModal() {
            this.checked = false;
            this.submitted = false;
        },
        submit: function() {
            this.submitted = true;

            this.$validator.validate().then(valid => {
                if (valid) {
                    this.showModal = true;

                    if (this.selectPais) {
                        var pais_codigo = this.selectPais;
                    } else {
                        pais_codigo = "";
                    }
                    if (this.selectEstado) {
                        var departamento_id = this.selectEstado;
                    } else {
                        departamento_id = "";
                    }
                    if (this.selectCiudad) {
                        var ciudad_id = this.selectCiudad;
                    } else {
                        ciudad_id = "";
                    }

                    let datos = this.userData;
                    datos["pais_codigo"] = pais_codigo;
                    datos["departamento_id"] = departamento_id;
                    datos["ciudad_id"] = ciudad_id;

                    api().post(`/api/casillero/miperfil`, datos, {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                            },
                        })
                        .then(response => {
                            const { data } = response;
                            if (data["msg"] == "") {
                                this.$swal(
                                    "Proceso completado",
                                    "Su perfil fue actualizado exitosamente",
                                    "success"
                                );
                                data["datos"]["pais"] = this.pais;
                                data["datos"]["departamento"] = this.estado;
                                data["datos"]["ciudad"] = this.ciudad;
                                localStorage.setItem(
                                    "datosCliente",
                                    JSON.stringify(data["datos"])
                                );
                                this.loadData();
                            } else {
                                this.$swal("error", data["msg"], "error");
                            }
                            this.checked = true;
                        })
                        .catch((error) => {
                            if (error.response && error.response.status === 401) {
                                this.refreshToken();
                            }
                        });
                }
            });
        }
    },
    data() {
        return {
            suma: true,
            buscarPostal: false,
            submitted: false,
            showModal: false,
            responseMessage: "",
            checked: false,
            confirmarEmail: "",
            tipo_de_cuenta_id: 1,
            userData: {
                primer_nombre: "",
                segundo_nombre: "",
                primer_apellido: "",
                segundo_apellido: "",
                tipo_de_identificacion_id: 1,
                numero_de_identificacion: "",
                email: "",
                telefono_celular: "",
                telefono_fijo: "",
                direccion: "",
                barrio: "",
                pais_codigo: "",
                departamento_id: "",
                ciudad_id: "",
                genero_id: "",
                codigo_postal_id: ""
            },
            codigo_postal: "",
            paises: null,
            estados: null,
            ciudades: null,
            selectPais: null,
            selectEstado: null,
            selectCiudad: null,
            pais: null,
            estado: null,
            ciudad: null
        };
    },
    mounted: function() {
        this.loadData();
        this.getPaises();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#miperfil {
    margin-top: 50px;
    padding: 10px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #b4141d;
}

.fondo-rojo {
    background: #b4141d !important;
}
</style>