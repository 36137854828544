import Vue from 'vue';
import Vuex from 'vuex';
import addSeconds from "date-fns/addSeconds";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token'),
    usuario: localStorage.getItem('datosUsuario'),
    user_name: localStorage.getItem('user_name'),
    refresh_token: localStorage.getItem('refresh_token'),
    tokensExpiry: localStorage.getItem('tokensExpiry')
  },

  mutations: {
    removeToken(state) {
      // TODO: For security purposes, take localStorage out of the project.
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('token_type')
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_name')
      localStorage.removeItem('cliente_id')
      localStorage.removeItem('datosUsuario')
      localStorage.removeItem('datosCliente')
      localStorage.removeItem('tokensExpiry')

      this.$cookies.remove('clienteId')

      state.token = null;
    },
    update_auth_tokens(state, tokenData) {
      localStorage.setItem("access_token", tokenData.access_token);
      state.token = localStorage.getItem("access_token");

      const tokensExpiry = addSeconds(new Date(), tokenData.expires_in);
      state.tokensExpiry = tokensExpiry;
      localStorage.setItem("tokensExpiry", tokensExpiry);

      localStorage.setItem('refresh_token', tokenData.refresh_token);
      state.refresh_token = localStorage.getItem("refresh_token");

      localStorage.setItem('token_type', tokenData.token_type);
    },
  },
  getters: {
    loggedIn(state) {
      return state.token !== null
    },
    logout(state) {
      return state.token
    },
    userName(state) {
      return state.user_name
    },
    tokensExpiry(state){
      return state.tokensExpiry
    }
  }

});