<template>
  <body>
    <div class="d-flex" id="wrapper">
      <!-- Page Content -->
      <div id="page-content-wrapper">
        <nav
          class="navbar navbar-expand-lg navbar-light bg-light border-bottom"
        >
          <a
            id="imagen"
            href="http://directionalcorporation.com"
            class="custom-logo-link"
            rel="home"
            aria-current="page"
          >
            <img
              width="410"
              height="38"
              src="assets/LogoH35-02.png"
              class="custom-logo container-fluid"
              alt="DIRECTIONAL CORP"
              srcset="/LogoH35-02.png 410w, /LogoH35-02-300x28.png 300w"
              sizes="(max-width: 410px) 100vw, 410px"
            />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="ocultarHeader"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="navbar-collapse collapse"
            id="navbarSupportedContent"
            style
          >
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
              <li class="nav-item active">
                <router-link v-show="!logueado" to="/"
                  ><a class="nav-link letra"
                    ><b-icon
                      icon="caret-right-square-fill"
                      aria-hidden="true"
                    ></b-icon>
                    Iniciar sesión</a
                  ></router-link
                >
              </li>
              <li v-show="!logueado" class="nav-item">
                <router-link to="/registrarse"
                  ><a class="nav-link letra"
                    ><b-icon
                      icon="person-plus-fill"
                      aria-hidden="true"
                    ></b-icon>
                    Registrarse</a
                  ></router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/rastrear-pedido"
                  ><a class="nav-link letra"
                    ><b-icon icon="geo-alt-fill" aria-hidden="true"></b-icon>
                    Rastrea tu pedido</a
                  ></router-link
                >
              </li>
              <li v-show="logueado && showBodega" class="nav-item">
                <a class="dropdown-item letra mt-1" href="#">BODEGA {{this.getBodegaNombre()}}</a>
              </li>
              <li v-show="logueado" class="nav-item dropdown">
                <a
                  @click="ocultarOpciones"
                  class="nav-link dropdown-toggle letra"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <b-icon icon="person-fill"></b-icon>
                  {{ this.$store.state.user_name }}
                </a>
                <div
                  id="dropdown"
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdown"
                >
                  <router-link to="/mi-perfil"
                    ><a class="dropdown-item letra"
                      ><b-icon icon="person-circle" aria-hidden="true"></b-icon>
                      Mi perfil</a
                    ></router-link
                  >
                  <router-link to="/cambiar-contrasena"
                    ><a class="dropdown-item letra"
                      ><b-icon icon="lock-fill" aria-hidden="true"></b-icon>
                      Cambiar contraseña</a
                    ></router-link
                  >
                  <router-link to="/destinatarios"
                    ><a class="dropdown-item letra"
                      ><b-icon icon="people-fill" aria-hidden="true"></b-icon>
                      Destinatarios</a
                    ></router-link
                  >
                  <router-link to="/pre-alerta"
                    ><a class="dropdown-item letra"
                      ><b-icon icon="bell-fill" aria-hidden="true"></b-icon> Pre
                      Alertas</a
                    ></router-link
                  >
                  <router-link to="/solicitar-despachos"
                    ><a class="dropdown-item letra"
                      ><b-icon icon="box-seam" aria-hidden="true"></b-icon>
                      Solicitar despachos</a
                    ></router-link
                  >
                  <router-link to="/historial"
                    ><a class="dropdown-item letra"
                      ><b-icon
                        icon="file-earmark-text-fill"
                        aria-hidden="true"
                      ></b-icon>
                      Historial</a
                    ></router-link
                  >
                  <div v-if="showBodega" class="dropdown-divider"></div>
                  <a v-if="showBodega" class="dropdown-item letra" @click="showModal"
                    ><b-icon
                      icon="box-arrow-in-right"
                      aria-hidden="true"
                    ></b-icon>
                    Bodega</a
                  >
                  <div class="dropdown-divider"></div>
                  <a
                    v-show="logueado"
                    @click="logout"
                    class="dropdown-item letra"
                    href="#"
                  >
                    <b-icon
                      icon="caret-left-square-fill"
                      aria-hidden="true"
                    ></b-icon>
                    Cerrar sesión
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <main class="main container-fluid">
          <slot />
        </main>
        <div>
          <b-modal
            ref="my-modal"
            title="SELECCIONAR BODEGA"
            size="lg"
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
          >
            <div class="container vertical-center login">
              <div class="inner-block">
                <div class="vue-tempalte">
                  <form @submit.prevent="loggedIn">
                    <div class="form-group">
                      <label>Contraseña</label>
                      <input
                        class="form-control"
                        v-validate="'required'"
                        :class="{
                          'is-invalid': submitted && errors.has('password'),
                        }"
                        id="password"
                        name="password"
                        type="password"
                        v-model="password"
                        required=""
                      />
                      <span
                        v-show="errors.has('password')"
                        class="invalid-feedback"
                        >Este campo es requerido</span
                      >
                    </div>
                    <div class="form-group">
                      <label for="">Bodega - ORLANDO</label>
                      <!-- <select
                        class="form-control"
                        v-validate="'required'"
                        name="selbodega"
                        id="selbodega"
                        @change="changeBodega()"
                        v-model="bodega"
                      >
                        <option
                          v-for="item in bodegaOptions"
                          :key="item.text"
                          :value="item.value"
                        >
                          {{ item.text }}
                        </option>
                      </select> -->
                    </div>
                    <button type="submit" class="btn btn-dark btn-block">
                      Iniciar Sesión
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
      <!-- /#page-content-wrapper -->
    </div>
    <!-- /#wrapper -->
  </body>
</template>

<script>
import { logear } from "../plugins/conn";
import { showBodega } from "../plugins/api_conect";

export default {
  data() {
    return {
      submitted: false,
      password: "",
      showBodega: showBodega(),
      bodega: 'orlando',
      bodegaOptions: [
        { text: "ORLANDO", value: "orlando" },
      ],
    };
  },
  computed: {
    logueado() {
      return this.$store.getters.loggedIn;
    },
  },
  methods: {
    ocultarHeader() {
      document
        .getElementById("navbarSupportedContent")
        .classList.toggle("show");
    },
    ocultarOpciones() {
      document.getElementById("dropdown").classList.toggle("show");
    },
    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("token_type");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_name");
      localStorage.removeItem("cliente_id");
      localStorage.removeItem("datosUsuario");
      localStorage.removeItem("datosCliente");
      localStorage.removeItem("tokensExpiry");
      localStorage.removeItem("bodega");

      this.$cookies.remove("clienteId");

      window.location.href = `${process.env.VUE_APP_URL}/`;
    },
    showModal() {
      this.submitted = true;
      this.password = "";
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.submitted = false;
      this.$refs["my-modal"].hide();
    },
    loggedIn() {
      this.submitted = true;
      logear(this.password, this.$store, this.$cookies, this.$swal, this.$refs["my-modal"]);
    },
    changeBodega() {
      localStorage.bodega = this.bodega;
    },
    getBodegaNombre(){

      for (const [key, value] of Object.entries(this.bodegaOptions)) {
      if (value.value == localStorage.getItem('bodega')){
           console.log(key);
          return value.text;
      }
    }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.letra {
  color: #b4141d !important;
}

.fondo-rojo {
  background: #b4141d !important;
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
</style>
