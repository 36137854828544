import { getSelectedEnvironment, api } from "../plugins/api_conect";

function logear(password, $store, $cookies, $swal, modal) {
  const currentEnvironment = getSelectedEnvironment();
  let username = JSON.parse(localStorage.getItem('datosUsuario')).email;
  let datos = {
    grant_type: "password",
    client_id: currentEnvironment.client_id,
    client_secret: currentEnvironment.client_secret,
    username: username,
    password: password,
    scope: "",
  };
  //SE REMPLAZA FETCH POR AXIOS
  api()
    .post("/oauth/token", datos, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      const { data } = response;
      $store.commit("update_auth_tokens", data);
      api()
        .get("/api/user", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: data.token_type + " " + data.access_token,
          },
        })
        .then((resp) => {
          localStorage.user_id = resp.data["datos"]["usuario"]["id"];
          localStorage.cliente_id = resp.data["datos"]["cliente"]["id"];
          localStorage.user_name = resp.data["datos"]["usuario"]["name"];

          localStorage.setItem(
            "datosUsuario",
            JSON.stringify(resp.data["datos"]["usuario"])
          );
          localStorage.setItem(
            "datosCliente",
            JSON.stringify(resp.data["datos"]["cliente"])
          );

          localStorage.setItem(
            "user_name",
            resp.data["datos"]["usuario"]["name"]
          );
          $cookies.set("clienteId", "true");
          modal.hide();
          location.reload();
        })
        .catch(() => {
          $swal(
            "Error",
            "Ocurrio un error, no se puede cambiar de bodega",
            "error"
          );
        });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        $swal("Error", "Usuario y/o contraseña incorrectos", "error");
      } else {
        $swal("Error", "Ocurrio un error, no se puede iniciar sesión", "error");
      }
    });
}
export { logear };
