<template>
    <div v-show="logueado" id="destinatarios" class="container">
        <h2>Destinatarios Casillero</h2>
        <div>
            <div id="crear" v-if="crear" class="separador contenido">
                <form @submit.prevent="button">
                    <br />
                    <h3 v-if="editarDestinatario">Editar Destinatario Casillero</h3>
                    <h3 v-else>Crear Destinatario Casillero</h3>
    
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>Primer nombre *</label>
                            <input id="primer_nombre" name="primer_nombre" v-model="userData.primer_nombre" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('primer_nombre') }" class="form-control" placeholder="Primer nombre" type="text" />
                            <span v-show="errors.has('primer_nombre')" class="invalid-feedback">Este campo es requerido</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Segundo nombre</label>
                            <input name="segundo_nombre" v-model="userData.segundo_nombre" class="form-control" placeholder="Segundo nombre" type="text" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>Primer apellido *</label>
                            <input name="primer_apellido" v-model="userData.primer_apellido" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('primer_apellido') }" class="form-control" placeholder="Primer apellido" type="text" />
                            <span v-show="errors.has('primer_apellido')" class="invalid-feedback">Este campo es requerido</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Segundo apellido</label>
                            <input name="segundo_apellido" v-model="userData.segundo_apellido" class="form-control" placeholder="Segundo apellido" type="text" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>Tipo de identificación *</label>
                            <select class="form-control" v-model="userData.tipo_de_identificacion_id" name="eTipoIdentificacion" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('eTipoIdentificacion') }">
                                <option
                                  v-for="identificacion in tiposDeIdentificacion"
                                  v-bind:key="identificacion.id"
                                  :value="identificacion.id"
                                >{{ identificacion.descripcion }}</option>
                              </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Número de identificación</label>
                            <input name="numero_de_identificacion" v-model="userData.numero_de_identificacion" class="form-control" placeholder="Nit o numero de identificacion" type="text" />
                        </div>
                    </div>
    
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>Email *</label>
                            <input v-validate="'required'" v-model="userData.email" name="email" type="email" :class="{'is-danger': errors.has('email')}" placeholder="Email" ref="email" class="form-control" />
                            <span v-show="errors.has('email')" class="small help text-danger">No es un email válido</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Confirmación email *</label>
                            <input v-validate="'required|confirmed:email'" name="email_confirmation" type="email" :class="{'is-danger': errors.has('email_confirmation')}" placeholder="Confirmar email" data-vv-as="email" class="form-control" />
                            <span v-show="errors.has('email_confirmation')" class="small help text-danger">El email no coincide</span>
                        </div>
                    </div>
    
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>Télefono fijo</label>
                            <input name="telefono_fijo" v-model="userData.telefono_fijo" class="form-control" placeholder="Teléfono fijo" type="text" />
                        </div>
    
                        <div class="form-group col-md-6">
                            <label class="mt-1 mb-0 ml-1">Teléfono celular *</label>
                            <input name="telefono_celular" v-model="userData.telefono_celular" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('telefono_celular') }" class="form-control" placeholder="Teléfono celular" type="text" />
                            <span v-show="errors.has('telefono_celular')" class="invalid-feedback">Este campo es requerido</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Dirección *</label>
                            <input name="direccion" v-model="userData.direccion" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('direccion') }" class="form-control" placeholder="Dirección" type="text" />
                            <span v-show="errors.has('direccion')" class="invalid-feedback">Este campo es requerido</span>
                        </div>
    
                        <div class="form-group col-md-6">
                            <label>Barrio</label>
                            <input name="barrio" v-model="userData.barrio" class="form-control" placeholder="Barrio" type="text" />
                        </div>
    
                        <div class="form-group col-md-6">
                            <label>País *</label>
                            <select class="form-control" v-model="selectPais" v-on:change="getEstados(selectPais)" name="ePais" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('ePais') }">
                                <option v-for="pais in paises" :value="pais.codigo" v-bind:key="pais.codigo">{{pais.nombre}}</option>
                              </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Departamento *</label>
                            <select class="form-control" v-model="selectEstado" v-on:change="getCiudades(selectEstado)" name="eEstado" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('eEstado') }">
                                <option
                                  v-for="estado in estados"
                                  :value="estado.id"
                                  v-bind:key="estado.id"
                                >{{estado.nombre}}</option>
                              </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Ciudad *</label>
                            <select class="form-control" v-model="selectCiudad" name="eCiudad" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('eCiudad') }">
                                <option
                                  v-for="ciudad in ciudades"
                                  :value="ciudad.id"
                                  v-bind:key="ciudad.id"
                                >{{ciudad.nombre}}</option>
                              </select>
                        </div>
                        <div class="form-group col-md-6">
                            <template>
                                <div>
                                  <label> Estado </label>
                                  <b-form-checkbox v-model="userData.activo" name="check-button" switch></b-form-checkbox>
                                </div>
</template>
            </div>
          </div>

          <p>los campos (*) son obligatorios</p>

          <div class="form-row">
            <div class="form-group col-md-5"></div>
            <div class="form-group col-md-6">
              <a
                class="btn btn-dark fondo-rojo"
                @click="closeCrear()"
                role="button"
                href="#"
              ><b-icon icon="x-square-fill" aria-hidden="true"></b-icon> Cancelar</a>
              <button class="btn btn-dark fondo-rojo"><b-icon icon="arrow-right-square-fill" aria-hidden="true"></b-icon> Registrar</button>
            </div>
          </div>
        </form>
      </div>
      <br />
      <div id="listar" class="separador contenido">
        <br />
        <br />

        <div class="panel panel-default">
          <div class="wrapper">
            <form class="form ng-pristine ng-valid">
              <div class="row">
                <div class="col-md-3 col-sm-5 col-xs-6">
                  <a class="btn btn-dark fondo-rojo" @click="toogle()" role="button" href="#"><b-icon icon="person-plus-fill" aria-hidden="true"></b-icon> Crear</a>
                </div>
                <div class="col-md-6 col-sm-4 col-xs-6">
                  <div class="form-row">
                    <div class="form-group col-md-2">
                      <label>Registros</label>
                    </div>
                    <div>
                      <select
                        class="form-control"
                        name="registros"
                        v-validate="'required'"
                        @change="changePage()"
                        :class="{ 'is-invalid': submitted && errors.has('registros') }"
                        v-model="registros"
                      >
                        <option selected="selected" value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-3 col-xs-12">
                  <input
                    type="text"
                    class="w-full app-input-group__m-t-xs form-control"
                    v-on:keyup="changePage"
                    placeholder="Buscar"
                    v-model="filtro"
                  />
                </div>
              </div>
            </form>
          </div>
          <br />
          <div class="table-responsive app-table">
            <table class="table table-striped b-t table-color" id="my-table">
              <thead>
                <tr>
                  <th>Opciones</th>
                  <th>Activo</th>
                  <th>SubCasillero</th>
                  <th>Nombre</th>
                  <th>Dirección</th>
                  <th>País</th>
                  <th>Departamento / Estado</th>
                  <th>Ciudad</th>
                  <th>Teléfono</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="destinatario in destinatarios" v-bind:key="destinatario.id">
                  <td>
                    <div class="row">
                      <button v-on:click="editar(destinatario)" class="btn btn-light padder-xs" title="Editar">
                        <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                      </button>
                      <button v-on:click="inactivar(destinatario)" class="btn btn-light padder-xs" v-if="destinatario.activo" title="Inactivar destinatario">
                        <b-icon icon="toggle2-off" aria-hidden="true"></b-icon>
                      </button>
                    </div>
                  </td>
                  <td>
                    <span v-if="destinatario.activo">Activo</span>
                    <span v-else>Inactivo</span>
                  </td>
                  <td class="ng-binding">{{destinatario.sub_casillero}}</td>
                  <td>{{destinatario.nombre_completo}}</td>
                  <td class="ng-binding">{{destinatario.direccion}}</td>
                  <td class="ng-binding">{{destinatario.pais.nombre}}</td>
                  <td class="ng-binding">{{destinatario.departamento.nombre}}</td>
                  <td class="ng-binding">{{destinatario.ciudad.nombre}}</td>
                  <td class="ng-binding">{{destinatario.telefono_celular}}</td>
                </tr>
              </tbody>
            </table>
            <div v-show="cargando" class="text-center app-bkg-color-f5 app-no-data-table b-t" >
              <b-col class="mb-3">
                <h3> <p>Cargando...</p> </h3>
                <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="4"></b-icon>
              </b-col>
            </div>
          </div>
          <div class="panel-footer">
            <b-pagination
              v-model="actualPagina"
              :total-rows="totalRegistros"
              :per-page="registros"
              aria-controls="my-table"
              align="right"
              v-on:input="changePage"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSelectedEnvironment, api } from "../plugins/api_conect";
const currentEnvironment = getSelectedEnvironment();

export default {
    computed: {
        logueado() {
            return this.$store.getters.loggedIn;
        }
    },
    methods: {
        button() {
            if (this.editarDestinatario) {
                this.submitEdit();
            } else {
                this.submit();
            }
        },
        clean() {
            (this.userData.primer_nombre = ""),
            (this.userData.primer_apellido = ""),
            (this.userData.segundo_nombre = ""),
            (this.userData.segundo_apellido = ""),
            (this.userData.tipo_de_identificacion_id = ""),
            (this.userData.numero_de_identificacion = ""),
            (this.userData.email = ""),
            (this.userData.telefono_celular = ""),
            (this.userData.telefono_fijo = ""),
            (this.userData.direccion = ""),
            (this.userData.pais_codigo = ""),
            (this.userData.departamento_id = ""),
            (this.userData.ciudad_id = ""),
            (this.userData.codigo_postal_id = ""),
            (this.userData.barrio = "");
        },
        async refreshToken() {
            let tokenExpiryDate = this.$store.getters.tokensExpiry;
            const now = new Date();
            if (new Date(tokenExpiryDate) < now) {
                this.logout();
            } else {
                let datos = {
                    grant_type: "refresh_token",
                    client_id: currentEnvironment.client_id,
                    client_secret: currentEnvironment.client_secret,
                    refresh_token: localStorage.getItem('refresh_token')
                };
                try {
                    const response = await api().post(`/oauth/token`, datos, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*"
                        },
                    });
                    const { data } = response;
                    localStorage.access_token = data.access_token;
                    localStorage.refresh_token = data.refresh_token;
                    localStorage.token_type = data.token_type;

                    this.$store.commit("update_auth_tokens", data);
                } catch (error) {
                    this.$swal(
                        "Error",
                        "Ocurrio un error",
                        "error"
                    );
                }
            }
        },
        logout() {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("token_type");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_name");
            localStorage.removeItem("cliente_id");
            localStorage.removeItem("datosUsuario");
            localStorage.removeItem("datosCliente");
            localStorage.removeItem("tokensExpiry");

            this.$cookies.remove("clienteId");

            window.location.href = `${process.env.VUE_APP_URL}/`;
        },
        getPaises: function() {
            api()
                .get(`/api/casillero/clientes/getCountries/${currentEnvironment.app_id}`)
                .then(response => {
                    const { data } = response;
                    this.paises = data;

                    if (this.pais) {
                        const indexPais = this.paises.findIndex(
                            x => x.codigo === this.pais.codigo
                        );
                        this.selectPais = this.paises[indexPais].codigo;
                        this.getEstados(this.selectPais);
                    }
                });
        },
        getEstados: function(codigoPais) {
            api()
                .get(`api/casillero/clientes/getDepartaments/${codigoPais}/${currentEnvironment.app_id}`)
                .then(response => {
                    const { data } = response;
                    if (data.length > 0) {
                        this.estados = data;

                        if (this.estado) {
                            const indexEstado = this.estados.findIndex(
                                x => x.id === this.estado.id
                            );
                            this.selectEstado = this.estados[indexEstado].id;
                            this.getCiudades(this.selectEstado);
                        }
                    } else {
                        this.selectEstado = "";
                        this.selectCiudad = "";
                    }
                });
        },
        getCiudades: function(idEstado) {
            api()
                .get(`/api/casillero/clientes/getCities/${idEstado}/${currentEnvironment.app_id}`)
                .then(response => {
                    const { data } = response;
                    if (data.length > 0) {
                        this.ciudades = data;

                        if (this.estado) {
                            const indexCiudad = this.ciudades.findIndex(
                                x => x.id === this.ciudad.id
                            );
                            this.selectCiudad = this.ciudades[indexCiudad].id;
                        }
                    } else {
                        this.selectCiudad = "";
                    }
                });
        },
        getTiposIdentificacion() {
            api()
                .get(`/api/tiposdeidentificacion/${currentEnvironment.app_id}`)
                .then(response => {
                    const { data } = response;
                    this.tiposDeIdentificacion = data;

                    if (this.tiposDeIdentificacion) {
                        this.selectIdentificacion = data.datos[1];
                        this.tiposDeIdentificacion = data.datos;
                    }
                });
        },
        submitEdit() {
            this.showModal = false;

            this.submittedEdit = true;

            this.$validator.validate().then(valid => {
                if (valid) {
                    if (this.selectPais) {
                        var pais_codigo = this.selectPais;
                    } else {
                        pais_codigo = "";
                    }
                    if (this.selectEstado) {
                        var departamento_id = this.selectEstado;
                    } else {
                        departamento_id = "";
                    }
                    if (this.selectCiudad) {
                        var ciudad_id = this.selectCiudad;
                    } else {
                        ciudad_id = "";
                    }

                    if (this.userData["numero_de_identificacion"] == "") {
                        var tipo_de_identificacion_id = "";
                    } else {
                        tipo_de_identificacion_id = parseInt(this.selectIdentificacion);
                    }

                    let datos = this.userData;
                    datos["pais_codigo"] = pais_codigo;
                    datos["departamento_id"] = departamento_id;
                    datos["ciudad_id"] = ciudad_id;
                    datos["tipo_de_identificacion_id"] = tipo_de_identificacion_id;
                    datos["cliente_id"] = localStorage.getItem('cliente_id');

                    api()
                        .post(`/api/casillero/destinatarios/${this.id}`, datos, {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
                            },

                        })
                        .then((response) => {
                            const { data } = response;
                            this.responseMessage = data["msg"];
                            this.$swal(
                                "Proceso Completado",
                                "Destinatario actualizado exitosamente",
                                "success"
                            );
                            this.changePage();
                            this.crear = false;
                            this.editarDestinatario = false;

                            this.checked = true;
                            this.clean();

                        }).catch(async (error) => {

                            if (error.response.status === 401) {
                                await this.refreshToken();
                                this.submitEdit();
                            } else {
                                for (let err in error.response.data["errores"]) {
                                    this.responseMessage += ". ";
                                    this.responseMessage += error.response.data["errores"][err];
                                }
                                this.checked = true;
                                this.clean();
                            }
                        });
                }
            });
        },
        editar(destinatario) {
            this.pais = destinatario.pais;
            this.estado = destinatario.departamento;
            this.ciudad = destinatario.ciudad;
            this.getPaises();
            this.showModal = false;

            this.userData = destinatario;
            this.crear = true;
            this.editarDestinatario = true;
            this.id = destinatario.id;

            document.getElementById("primer_nombre").focus();
        },
        inactivar(objeto) {
            this.showModal = false;

            objeto.activo = false;

            api()
                .post(`/api/casillero/destinatarios/${objeto.id}`, objeto, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`,
                    },
                })
                .then((response) => {
                    const { data } = response;
                    this.responseMessage = data["msg"];
                    this.changePage();
                    this.$swal(
                        "Proceso completado",
                        "Destinatario inactivado con éxito",
                        "success"
                    );

                }).catch(async (error) => {
                    if (error.response.status === 401) {
                        await this.refreshToken();
                        this.inactivar(objeto);
                    } else {
                        for (let err in error.response.data["errores"]) {
                            this.responseMessage += ". ";
                            this.responseMessage += error.response.data["errores"][err];
                        }
                        this.checked = true;
                    }
                });
        },
        toogle() {
            this.crear = true;
        },
        closeCrear() {
            this.crear = false;
            this.clean();
            this.changePage();
        },
        closeEdit() {
            this.editarDiv = false;
        },
        closeModal() {
            this.showModal = false;
            this.checked = false;
            this.submitted = false;
            this.editarDiv = false;

            if (this.responseMessage == "Destinatario creado exitosamente") {
                this.crear = false;
            }
            if (this.responseMessage == "Destinatario actualizado exitosamente") {
                this.crear = false;
                this.editarDestinatario = false;
            }
        },
        changePage() {
            this.destinatarios = [];
            this.cargando = true;

            var datos = {
                str_busqueda: this.filtro,
                pageSize: this.registros
            };

            api().post(`/api/casillero/clientes/destinatarios?page=${this.actualPagina}`, datos, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                })
                .then((response) => {
                    const { data } = response;


                    if (data["datos"] != null) {
                        this.destinatarios = data["datos"]["data"];
                        this.totalRegistros = data["datos"]["total"];
                        this.totalPaginas = data["datos"]["last_page"];
                        this.actualPagina = data["datos"]["current_page"];
                        this.authenticated = true;
                        this.cargando = false;
                    }
                }).catch(async (error) => {
                    if (error.response.status === 401) {
                        await this.refreshToken();
                        this.changePage();
                        this.getPaises();
                    } else {
                        this.$swal(
                            "Error",
                            error,
                            "error"
                        );
                    }
                });
        },
        submit: function() {
            this.submitted = true;

            this.$validator.validate().then(valid => {
                if (valid) {
                    if (this.selectPais) {
                        var pais_codigo = this.selectPais;
                    } else {
                        pais_codigo = "";
                    }
                    if (this.selectEstado) {
                        var departamento_id = this.selectEstado;
                    } else {
                        pais_codigo = "";
                    }
                    if (this.selectCiudad) {
                        var ciudad_id = this.selectCiudad;
                    } else {
                        pais_codigo = "";
                    }

                    if (this.userData["numero_de_identificacion"] == "") {
                        var tipo_de_identificacion_id = "";
                    } else {
                        tipo_de_identificacion_id = parseInt(this.selectIdentificacion);
                    }

                    let datos = this.userData;
                    datos["pais_codigo"] = pais_codigo;
                    datos["departamento_id"] = departamento_id;
                    datos["ciudad_id"] = ciudad_id;
                    datos["tipo_de_identificacion_id"] = tipo_de_identificacion_id;
                    datos["cliente_id"] = localStorage.getItem('cliente_id');

                    api().post(`/api/casillero/destinatarios`, datos, {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                            },
                        })
                        .then((response) => {
                            const { data } = response;
                            this.responseMessage = data["msg"];
                            this.$swal(
                                "Proceso completado",
                                "Destinatario creado exitosamente",
                                "success"
                            );
                            this.clean();
                            this.crear = false;
                            this.changePage();

                        }).catch(async (error) => {
                            if (error.response.status === 401) {
                                await this.refreshToken();
                                this.submit();
                            } else {
                                for (let err in error.response.data["errores"]) {
                                    this.responseMessage += ". ";
                                    this.responseMessage += error.response.data["errores"][err];
                                }
                            }

                        });
                }
            });
        }
    },
    data() {
        return {
            authenticated: false,
            cargando: false,
            submitted: false,
            submittedEdit: false,
            showModal: false,
            showEdit: false,
            responseMessage: "",
            checked: false,
            userData: {
                primer_nombre: "",
                primer_apellido: "",
                segundo_nombre: "",
                segundo_apellido: "",
                tipo_de_identificacion_id: "",
                numero_de_identificacion: "",
                email: "",
                telefono_celular: "",
                telefono_fijo: "",
                direccion: "",
                pais_codigo: "",
                departamento_id: "",
                ciudad_id: "",
                codigo_postal_id: "",
                barrio: "",
                activo: true
            },
            registros: 10,
            actualPagina: 1,
            totalPaginas: 0,
            totalRegistros: 0,
            filtro: "",
            destinatarios: [],
            crear: false,
            editarDiv: false,
            editarDestinatario: false,
            paises: null,
            estados: null,
            ciudades: null,
            selectPais: null,
            selectEstado: null,
            selectCiudad: null,
            tiposDeIdentificacion: null,
            pais: null,
            estado: null,
            ciudad: null,
            id: ""
        };
    },
    mounted: function() {
        this.changePage();
        this.getPaises();
        this.getTiposIdentificacion();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contenido {
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #b4141d;
}

.fondo-rojo {
    background: #b4141d !important;
}

.table-color {
    color: black !important;
    background: aliceblue !important;
}
</style>