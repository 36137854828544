<template>
    <div v-show="logueado" id="historial" class="container">
        <h2>Historial</h2>
    
        <div id="filtros" class="separador contenido">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>Fecha Inicio</label>
                    <div>
                        <input class="form-control col-10" type="date" v-model="buscar.fecha_inicial" name="fecha_inicial" />
                    </div>
                </div>
                <div class="form-group col-md-5">
                    <label>Proceso</label>
                    <select class="form-control" name="estado" v-model="buscar.tipo_proceso">
                <option selected="selected" value="TRANSITO">TRANSITO</option>
                <option value="ENTREGADA">ENTREGADA</option>
                <option value="SIN SOLICITUD">SIN SOLICITUD</option>
              </select>
                </div>
    
                <div class="form-group col-md-6">
                    <label>Fecha Fin</label>
                    <div>
                        <input class="form-control col-10" type="date" v-model="buscar.fecha_final" name="fechafinal" />
                    </div>
                </div>
    
                <div class="form-group col-md-6">
                    <label>Contenido</label>
                    <div>
                        <input class="form-control col-10" type="text" v-model="buscar.str_busqueda" />
                    </div>
                </div>
    
                <div>
                    <b-modal title="Rastrear" size="lg" ok-only ok-variant="dark" v-model="modalShow">
                        <div class="panel panel-default ng-scope">
                            <div class="panel-heading font-bold ng-binding">
                                <span>Trackings : {{track}}</span>
                                <br /> Hawb : {{hawb}}
                                <br />
                            </div>
                            <table class="table table-striped b-t ng-hide" v-if="guias_agrupadas.length > 0">
                                <thead>
                                    <tr>
                                        <th colspan="3">Guías agrupadas</th>
                                    </tr>
                                    <tr>
                                        <th>Fecha de creación</th>
                                        <th>HAWB</th>
                                        <th>Tracking</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="guia in guias_agrupadas" v-bind:key="guia.id">
                                        <td>{{guia.created_at}}</td>
                                        <td>{{guia.hawb}}</td>
                                        <td>{{guia.tracking_tienda_casillero}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- ngIf: vm.trackings.length == 0 -->
                            <div class="table-responsive app-table">
                                <table class="table table-striped b-t">
                                    <thead>
                                        <tr>
                                            <th class="text-center" colspan="3">Trackings</th>
                                        </tr>
                                        <tr>
                                            <th>Fecha de creación</th>
                                            <th>Estado</th>
                                            <th>Observación</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="tracking in trackings" v-bind:key="tracking.id">
                                            <td>{{tracking.created_at}}</td>
                                            <td>{{tracking.estado_de_guia.descripcion_alterna}}</td>
                                            <td>{{tracking.observacion}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- ngIf: vm.trackings.length == 0 -->
                            </div>
                        </div>
                    </b-modal>
                </div>
    
                <div class="form-group col-md-10"></div>
                <div class="form-group col-md-2">
                    <button class="btn btn-dark btn-block fondo-rojo" @click="changePage()"><b-icon icon="search" aria-hidden="true"></b-icon> Buscar</button>
                </div>
            </div>
        </div>
    
        <br />
    
        <div id="listar" class="separador contenido">
            <br />
            <br />
    
            <div class="panel panel-default">
                <div class="wrapper">
                    <form class="form ng-pristine ng-valid">
                        <div class="row">
                            <div class="col-md-6 col-sm-5 col-xs-6"></div>
                            <div class="col-md-6 col-sm-4 col-xs-6">
                                <div class="form-row">
                                    <div class="form-group col-md-2">
                                        <label>Registro</label>
                                    </div>
                                    <div class="form-group">
                                        <select class="form-control" name="pageSize" v-validate="'required'" @change="changePage()" :class="{ 'is-invalid': submitted && errors.has('pageSize') }" v-model="buscar.pageSize">
                          <option selected="selected" value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-3 col-xs-12">
                                <!--
                                            <input type="text" class="w-full app-input-group__m-t-xs form-control" v-on:keyup="changePage" placeholder="Buscar" v-model="filtro">
                    -->
                            </div>
                        </div>
                    </form>
                </div>
                <br />
                <div class="table-responsive app-table">
                    <table class="table table-striped b-t table-color" id="my-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Fecha de creación</th>
                                <th>Tracking</th>
                                <th>HAWB</th>
                                <th>Contenido</th>
                                <th>Estado</th>
                                <th>Peso lbs</th>
                                <th>Observación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="paquete in paquetes" v-bind:key="paquete.id">
                                <td>
                                    <b-button variant="outline-dark" @click="modalShow = !modalShow ; rastrear(paquete)">
                                        <b-icon icon="truck" aria-hidden="true"></b-icon> Rastrear
                                    </b-button>
                                </td>
                                <td class="ng-binding">{{paquete.created_at}}</td>
                                <td class="ng-binding">{{paquete.tracking_tienda_casillero}}</td>
                                <td class="ng-binding">{{paquete.hawb}}</td>
                                <td class="ng-binding">{{paquete.contenido}}</td>
                                <td class="ng-binding">{{paquete.estados_de_guia_descripcion}}</td>
                                <td class="ng-binding">{{paquete.peso}}</td>
                                <td class="ng-binding">{{paquete.observacion_tracking}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-show="cargando" class="text-center app-bkg-color-f5 app-no-data-table b-t">
                        <b-col class="mb-3">
                            <h3>
                                <p>Cargando...</p>
                            </h3>
                            <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="4"></b-icon>
                        </b-col>
                    </div>
                </div>
                <div class="panel-footer">
                    <b-pagination v-model="buscar.pageNumber" :total-rows="totalRegistros" :per-page="buscar.pageSize" aria-controls="my-table" align="right" v-on:input="changePage"></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getSelectedEnvironment, api } from "../plugins/api_conect";

export default {
    computed: {
        logueado() {
            return this.$store.getters.loggedIn;
        }
    },
    methods: {
        rastrear(paquete) {
            api().get(`/api/casillero/historial/rastrear/${paquete.id}`, {
                    method: "get",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    }
                })
                .then((response) => {
                    const { data } = response
                    this.guias_agrupadas = data["datos"]["guias_agrupadas"];
                    this.trackings = data["datos"]["trackings"];
                    this.track = paquete.tracking_tienda_casillero;
                    this.hawb = paquete.hawb;
                    this.rastrearModal = true;
                    this.checked = true;

                }).catch(async (error) => {
                    if (error.response && error.response.status === 401) {
                        await this.refreshToken();
                        this.rastrear(paquete);
                    }
                    this.checked = true;
                });
        },
        async refreshToken() {
            const currentEnvironment = getSelectedEnvironment();
            let tokenExpiryDate = this.$store.getters.tokensExpiry;
            const now = new Date();
            if (new Date(tokenExpiryDate) < now) {
                this.logout();
            } else {
                this.$validator.validate().then(async valid => {
                    if (valid) {
                        let datos = {
                            grant_type: "refresh_token",
                            client_id: currentEnvironment.client_id,
                            client_secret: currentEnvironment.client_secret,
                            refresh_token: localStorage.getItem('refresh_token')
                        };
                        try {
                            const response = await api().post(`/oauth/token`, datos, {
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*"
                                },
                            });
                            const { data } = response;
                            localStorage.access_token = data.access_token;
                            localStorage.refresh_token = data.refresh_token;
                            localStorage.token_type = data.token_type;

                            this.$store.commit("update_auth_tokens", data);
                        } catch (error) {
                            this.$swal(
                                "Error",
                                "Ocurrio un error",
                                "error"
                            );
                        }
                    }
                });
            }
        },
        logout() {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("token_type");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_name");
            localStorage.removeItem("cliente_id");
            localStorage.removeItem("datosUsuario");
            localStorage.removeItem("datosCliente");
            localStorage.removeItem("tokensExpiry");

            this.$cookies.remove("clienteId");

            window.location.href = `${process.env.VUE_APP_URL}/`;
        },
        changePage() {
            this.paquetes = [];
            this.cargando = true;
            this.showModal = true;


            this.buscar.pageNumber = parseInt(this.buscar.pageNumber);
            this.buscar.pageSize = parseInt(this.buscar.pageSize);
            api().post(`/api/casillero/clientes/historial/busqueda?page=${this.buscar.pageNumber}`, this.buscar, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    },
                })
                .then((response) => {
                    const { data } = response;
                    this.cargando = false;
                    this.paquetes = data["datos"]["data"];
                    this.totalRegistros = data["datos"]["total"];
                    this.showModal = false;
                }).catch(async (error) => {
                    if (error.response && error.response.status === 401)
                      await this.refreshToken();
                      this.changePage();
                });
        },

        toogle() {
            this.crear = true;
        },
        closeCrear() {
            this.crear = false;
            this.clean();
            this.changePage();
        },
        clean() {
            this.preAlerta = {
                tracking: "",
                cliente_id: "",
                tienda_id: "",
                contenido: "",
                destinatario_id: "",
                valor_comercial: "",
                valor_asegurado: "",
                posicion_arancelaria_id: "",
                orden_de_compra: "",
                factura: "",
                notas: "",
                estado: true
            };
        },
        closeModal() {
            this.rastrearModal = false;
        }
    },
    data() {
        return {
            modalShow: false,
            cargando: false,
            track: "",
            hawb: "",
            trackings: "",
            guias_agrupadas: "",
            checked: false,
            rastrearModal: false,
            paquetes: "",
            crear: false,
            submitted: false,
            showModal: false,
            responseMessage: "",
            totalPaginas: 0,
            totalRegistros: 0,

            buscar: {
                fecha_inicial: "",
                fecha_final: "",
                str_busqueda: "",
                cliente_id: localStorage.cliente_id,
                tipo_proceso: "TRANSITO",
                pageNumber: 1,
                pageSize: 10
            }
        };
    },
    mounted: function() {
        this.changePage();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contenido {
    padding: 10px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #b4141d;
}

.table-color {
    color: black !important;
    background: aliceblue !important;
}

.text-center {
    text-align: center;
}

.fondo-rojo {
    background: #b4141d !important;
}
</style>