import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin, NavbarPlugin } from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import VueCookies from 'vue-cookies'
import vSelect from 'vue-select'
import VueRouter from 'vue-router'
import {routes} from './routes.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {store} from './store'
import Default from './layouts/Default.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

Vue.component('default-layout', Default)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Install VeeValidate
Vue.use(VeeValidate)
// Install VueCookies
Vue.use(VueCookies)
// Install VueRouter
Vue.use(VueRouter)
// Install NavbarPlugin
Vue.use(NavbarPlugin)
// Install SweetAlert
Vue.use(VueSweetalert2);
// Global registration
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.loggedIn) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

Vue.component("v-select", vSelect);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
