<template>
    <div class="vertical-center cambiarcontrasena">
        <div class="inner-block">
            <div class="vue-tempalte">
                <form @submit.prevent="submit">
                    <h3>Cambiar Contraseña</h3>
    
                    <div class="form-group">
                        <label>Contraseña actual</label>
                        <input class="form-control" v-validate="'required'" v-model="userData.password_old" name="password_old" type="password" placeholder="Contraseña actual" ref="password_old" required />
                    </div>
                    <div class="form-group">
                        <label>Contraseña nueva</label>
                        <input class="form-control" v-validate="'required'" v-model="userData.password" name="password" type="password" placeholder="Contraseña nueva" ref="password" required />
                    </div>
                    <div class="form-group">
                        <label>Confirmar Contraseña</label>
                        <input class="form-control" v-validate="'required'" v-model="userData.password_confirmation" name="password_confirmation" type="password" placeholder="Confirmar contraseña" ref="password_confirmation" required />
                    </div>
                    <button type="submit" class="btn btn-dark btn-block">Enviar</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { getSelectedEnvironment, api } from "../plugins/api_conect";

export default {
    name: "CambiarContrasena",
    computed: {
        logueado() {
            return this.$store.getters.loggedIn;
        }
    },
    methods: {
        submit: function() {
            this.$validator.validate().then(valid => {
                if (valid) {

                    const datos = {
                        password_old: this.userData.password_old,
                        password: this.userData.password,
                        password_confirmation: this.userData.password_confirmation
                    };

                    api().post(`/api/usuarios/clave`, datos, {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                            },
                        })
                        .then(response => {
                            const {data} = response;
                            if (response.status === 200) {
                                this.userData = {};
                                this.$swal("Proceso completo", data["msg"], "success");
                            }else{
                                this.$swal("Error", data["msg"], "error");
                            }
                        }).catch((error) => {
                          if(error.reponse && error.reponse.status === 401){
                               this.refreshToken();
                          }else{
                                this.$swal("Error", "Error", "error");
                            }

                        });
                }
            });
        },
        async refreshToken() {
            const currentEnvironment = getSelectedEnvironment();
            let tokenExpiryDate = this.$store.getters.tokensExpiry;
            const now = new Date();
            if (new Date(tokenExpiryDate) < now) {
                this.logout();
            } else {
                let datos = {
                    grant_type: "refresh_token",
                    client_id: currentEnvironment.client_id,
                    client_secret: currentEnvironment.client_secret,
                    refresh_token: localStorage.getItem('refresh_token')
                };
                try {
                    const response = await api().post(`/oauth/token`, datos, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*"
                        },
                    });
                    const { data } = response;
                    localStorage.access_token = data.access_token;
                    localStorage.refresh_token = data.refresh_token;
                    localStorage.token_type = data.token_type;

                    this.$store.commit("update_auth_tokens", data);
                } catch (error) {
                    this.$swal(
                        "Error",
                        "Ocurrio un error",
                        "error"
                    );
                }
            }
        },
        logout() {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("token_type");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_name");
            localStorage.removeItem("cliente_id");
            localStorage.removeItem("datosUsuario");
            localStorage.removeItem("datosCliente");
            localStorage.removeItem("tokensExpiry");

            this.$cookies.remove("clienteId");

            window.location.href = `${process.env.VUE_APP_URL}/`;
        },
    },
    data() {
        return {
            userData: {
                password_old: "",
                password: "",
                password_confirmation: ""
            }
        };
    }
};
</script>

<style scoped>
.cambiarcontrasena {
    min-height: 80vh;
    display: flex;
    font-weight: 400;
}

body,
html,
.App,
.vue-tempalte,
.vertical-center {
    width: 100%;
    height: 100%;
}

.vertical-center {
    display: flex;
    text-align: left;
    justify-content: center;
    flex-direction: column;
}

.inner-block {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
}

@media (max-width: 768px) {
    .inner-block {
        width: auto;
    }
}

.vertical-center .form-control:focus {
    border-color: #b4141d;
    box-shadow: none;
}

.vertical-center h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
}

label {
    font-weight: 500;
}
</style>