import Login from './components/Login.vue'
import Registrarse from './components/Registrarse.vue'
import RecuperarContrasena from './components/RecuperarContrasena.vue'
import MiPerfil from './components/MiPerfil.vue'
import RastrearPedido from './components/RastrearPedido.vue'
import Prealerta from './components/Prealerta.vue'
import Destinatarios from './components/Destinatarios.vue'
import Historial from './components/Historial.vue'
import SolicitarDespachos from './components/SolicitarDespachos.vue'
import CambiarContrasena from './components/CambiarContrasena.vue'
import ResetPassword from './components/ResetPassword.vue'

export const routes = [
  { path: '/', component: Login, name: 'Login' },
  { path: '/registrarse', component: Registrarse },
  { path: '/recuperar-contrasena', component: RecuperarContrasena },
  { path: '/rastrear-pedido', component: RastrearPedido },
  { path: '/reset-password', component: ResetPassword },
  {
    path: '/mi-perfil', component: MiPerfil, meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pre-alerta', component: Prealerta, meta: {
      requiresAuth: true
    }
  },
  {
    path: '/destinatarios', component: Destinatarios, meta: {
      requiresAuth: true
    }
  },
  {
    path: '/historial', component: Historial, meta: {
      requiresAuth: true
    }
  },
  {
    path: '/solicitar-despachos', component: SolicitarDespachos, meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cambiar-contrasena', component: CambiarContrasena, meta: {
      requiresAuth: true
    }
  }
];

