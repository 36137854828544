<template>
  <div class="modal">
    <transition name="modal">
      <div class="modal" style="display: block">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
            <div class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Modal",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>