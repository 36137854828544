<template>
    <div v-show="logueado" id="prealerta" class="container">
        <h2>Prealertas</h2>
        <div id="crear" v-if="crear" class="separador contenido">
            <form @submit.prevent="submit">
                <h2 v-if="editarPreAlerta">Editar Prealerta</h2>
                <h2 v-else>Crear Prealerta</h2>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label id="labelColor">Tracking *</label>
                        <input id="tracking" name="tracking" v-model="preAlerta.tracking" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('tracking') }" class="form-control" placeholder="Tracking" type="text" />
                        <span v-show="errors.has('tracking')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                    <div class="form-group col-md-6">
                        <label id="labelColor">Tienda *</label>
                        <vue-bootstrap-typeahead v-model="selectTienda" :data="arrayTiendas" />
                        <p>
                            Tienda Actual:
                            <strong>{{selectTienda}}</strong>
                        </p>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label id="labelColor">Contenido *</label>
                        <input name="contenido" v-model="preAlerta.contenido" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('contenido') }" class="form-control" placeholder="Contenido" type="text" />
                        <span v-show="errors.has('contenido')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                    <div class="form-group col-md-6">
                        <label id="labelColor">Destinatarios *</label>
                        <select class="form-control" v-model="preAlerta.destinatario_id" name="destinatario" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('destinatario') }">
                  <option
                    v-for="destinatario in destinatarios"
                    :value="destinatario.id"
                    v-bind:key="destinatario.id"
                  >{{destinatario.nombre_completo}}</option>
                </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label id="labelColor">Valor Comercial *</label>
                        <input name="valor_comercial" v-model="preAlerta.valor_comercial" class="form-control" type="number" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('valor_comercial') }" />
                        <span v-show="errors.has('valor_comercial')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                    <div class="form-group col-md-6">
                        <label id="labelColor">Valor Asegurado *</label>
                        <input name="valor_asegurado" v-model="preAlerta.valor_asegurado" class="form-control" type="number" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('valor_asegurado') }" />
                        <span v-show="errors.has('valor_asegurado')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                </div>
    
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label id="labelColor">Posiciones Arancelarias *</label>
                        <select class="form-control" v-model="preAlerta.posicion_arancelaria_id">
                  <option
                    v-for="posicion in posiciones"
                    :value="posicion.id"
                    v-bind:key="posicion.id"
                  >{{posicion.descripcion}}</option>
                </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label id="labelColor">Orden de Compra *</label>
                        <input name="orden_de_compra" v-model="preAlerta.orden_de_compra" v-validate="'required'" :class="{ 'is-invalid': submitted && errors.has('orden_de_compra') }" class="form-control" placeholder="Orden de compra" type="text" />
                        <span v-show="errors.has('orden_de_compra')" class="invalid-feedback">Este campo es requerido</span>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label id="labelColor">Copia Factura</label>
                        <b-form-file v-model="preAlerta.factura" :state="Boolean(file)" placeholder="Choose a file..." drop-placeholder="Drop file here..."></b-form-file>
                        <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="mt-1 mb-0 ml-1">Notas</label>
                        <textarea name="notas" v-model="preAlerta.notas" class="form-control" placeholder="Notas" type="text"></textarea>
                    </div>
                    <div class="form-group col-md-6">
                        <template>
                  <div>
                    <b-form-checkbox v-model="preAlerta.estado" name="check-button" switch>Estado</b-form-checkbox>
                  </div>
</template>
          </div>
          <div class="form-group col-md-6">
<template>
    <div>
        <b-form-checkbox v-model="preAlerta.retener" name="check-button" switch>Retener</b-form-checkbox>
    </div>
</template>
          </div>
        </div>
        <p>los campos (*) son obligatorios</p>
        <div class="form-row">
          <div class="form-group col-md-5"></div>
          <div class="form-group col-md-6">
            <a class="btn btn-dark fondo-rojo" @click="closeCrear()" role="button" href="#"><b-icon icon="x-square-fill" aria-hidden="true"></b-icon> Cancelar</a>
            <button class="btn btn-dark fondo-rojo"><b-icon icon="arrow-right-square-fill" aria-hidden="true"></b-icon> Registrar</button>
          </div>
        </div>
      </form>
    </div>
    <br />
    <div id="filtros" class="separador contenido">
      <div class="form-row">
        <div class="form-group col-md-5">
          <label>Fecha Inicio</label>
          <div class="col-10">
            <input class="form-control" type="date" v-model="buscar.fechaIni" name="fechaIni" />
          </div>
        </div>
        <div class="form-group col-md-5">
          <label>Fecha Fin</label>
          <div class="col-10">
            <input class="form-control" type="date" v-model="buscar.fechaFin" name="fechaFin" />
          </div>
        </div>
        <div class="form-group col-md-2">
          <label id="labelColor">Activo</label>
          <select class="form-control" name="estado" v-model="buscar.estado">
            <option selected="selected" value="1">Si</option>
            <option value="0">No</option>
            <option value>Ambos</option>
          </select>
        </div>

        <div class="form-group col-md-10"></div>
        <div class="form-group col-md-2">
          <button class="btn btn-dark btn-block fondo-rojo" @click="buscarFiltro()"><b-icon icon="search" aria-hidden="true"></b-icon> Buscar</button>
        </div>
      </div>
    </div>
    <br />

    <div id="listar" class="separador contenido">
      <div class="panel panel-default">
        <div class="wrapper">
          <form class="form ng-pristine ng-valid">
            <div class="row">
              <div class="col-md-4 col-sm-5 col-xs-6">
                <a
                  class="btn btn-dark fondo-rojo"
                  @click="toogle()"
                  role="button"
                  href="#"
                  v-show="checkTienda"
                ><b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon> Crear</a>
              </div>
              <div class="col-md-6 col-sm-4 col-xs-6">
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <label>Registros</label>
                  </div>
                  <div class="form-group">
                    <select
                      class="form-control"
                      name="registros"
                      v-validate="'required'"
                      @change="changePage()"
                      :class="{ 'is-invalid': submitted && errors.has('registros') }"
                      v-model="registros"
                    >
                      <option selected="selected" value="15">15</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-3 col-xs-12"></div>
            </div>
          </form>
        </div>
        <br />
        <div class="table-responsive app-table">
          <table class="table table-striped b-t table-color" id="my-table">
            <thead>
              <tr>
                <th>Opciones</th>
                <th>Activo</th>
                <th>Retener</th>
                <th>ID</th>
                <th>Fecha</th>
                <th>Tracking</th>
                <th>Destinatario</th>
                <th>Tienda</th>
                <th>Contenido</th>
                <th>Val. Asegurado</th>
                <th>Val. Comercial</th>
                <th>Orden de Compra</th>
                <th>Notas</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="preAle in preAlertas" v-bind:key="preAle.id">
                <td>
                  <button
                    v-on:click="editar(preAle)"
                    class="btn btn-light padder-xs"
                    v-show="checkTienda"
                  >
                    <b-icon icon="pencil-fill" aria-hidden="true" title="Editar"></b-icon>
                  </button>
                </td>
                <td>
                  <span v-if="preAle.estado">Activa</span>
                  <span v-else>Inactiva</span>
                </td>
                <td>
                  <span v-if="preAle.retener">Si</span>
                  <span v-else>No</span>
                </td>
                <td class="ng-binding">{{preAle.id}}</td>
                <td class="ng-binding">{{preAle.fecha}}</td>
                <td class="ng-binding">{{preAle.tracking}}</td>
                <td class="ng-binding">{{preAle.destinatario.nombre_completo}}</td>
                <td class="ng-binding">{{preAle.tienda.razon_social}}</td>
                <td class="ng-binding">{{preAle.contenido}}</td>
                <td class="ng-binding">{{preAle.valor_asegurado}}</td>
                <td class="ng-binding">{{preAle.valor_comercial}}</td>
                <td class="ng-binding">{{preAle.orden_de_compra}}</td>
                <td class="ng-binding">{{preAle.notas}}</td>
              </tr>
            </tbody>
          </table>
          <div v-show="cargando" class="text-center app-bkg-color-f5 app-no-data-table b-t" >
            <b-col class="mb-3">
              <h3> <p>Cargando...</p> </h3>
              <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="4"></b-icon>
            </b-col>
          </div>
        </div>
        <div class="panel-footer">
          <b-pagination
            v-model="actualPagina"
            :total-rows="totalRegistros"
            :per-page="registros"
            aria-controls="my-table"
            align="right"
            v-on:input="changePage"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSelectedEnvironment, api } from "../plugins/api_conect";

export default {
    computed: {
        logueado() {
            return this.$store.getters.loggedIn;
        }
    },
    methods: {
        buscarFiltro() {
            let d1 = null;
            if (this.buscar.fechaIni){
                d1 = new Date(this.buscar.fechaIni);
            }
            let d2 = null;
            if (this.buscar.fechaFin){
                d2 = new Date(this.buscar.fechaFin);
            }

            if (d1 < d2) {
                api().get(`/api/casillero/clientes/prealertasGet/${this.registros}/${this.buscar.estado}/${d1}/${d2}/null?page=${this.actualPagina}`, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                        },
                    })
                    .then(response => {
                        const { data } = response;

                        this.preAlertas = data["datos"]["data"];
                        this.totalRegistros = data["datos"]["total"];
                        this.totalPaginas = data["datos"]["last_page"];
                        this.actualPagina = data["datos"]["current_page"];
                    }).catch(async (error) => {
                        if (error.response.status === 401) {
                            this.$swal("error", "Error de autentificación", "error");
                            await this.refreshToken();
                            this.buscarFiltro();
                        } else {
                            this.$swal("error", "Error", "error");
                        }
                    });
            } else {
                this.$swal(
                    "error",
                    "La fecha final debe ser posterior a la inicial.",
                    "error"
                );
                this.checked = true;
            }
        },
        editar(preAlerta) {
            this.showModal = false;
            this.crear = true;

            this.preAlerta = preAlerta;

            const indexTienda = this.arrayTiendas.findIndex(
                x => x === preAlerta.tienda["razon_social"]
            );
            this.selectTienda = this.arrayTiendas[indexTienda];

            // const indexDestinatario = this.destinatarios.findIndex(x => x.id === preAlerta.destinatario.id);
            this.selectDestinatario = preAlerta.destinatario;
            // const indexPosicion = this.posiciones.findIndex(x => x.id === preAlerta.posicion_arancelaria_id);
            this.selectPosicion = preAlerta.posicion_arancelaria_id;
            this.preAlerta.factura = [];

            this.editarPreAlerta = true;
            this.id = preAlerta.id;

            document.getElementById("tracking").focus();
        },
        changePage() {
            this.preAlertas = [];
            this.cargando = true;
            let d1 = null;
            if (this.buscar.fechaIni){
                d1 = new Date(this.buscar.fechaIni);
            }
            let d2 = null;
            if (this.buscar.fechaFin){
                d2 = new Date(this.buscar.fechaFin);
            }
            api().get(`/api/casillero/clientes/prealertasGet/${this.registros}/${this.buscar.estado}/${d1}/${d2}/null?page=${this.actualPagina}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    },
                })
                .then(response => {
                    const { data } = response;
                    this.cargando = false;
                    this.preAlertas = data["datos"]["data"];
                    this.totalRegistros = data["datos"]["total"];
                    this.totalPaginas = data["datos"]["last_page"];
                    this.actualPagina = data["datos"]["current_page"];

                }).catch(async (error) => {
                    if (error.response.status === 401) {
                        this.$swal("error", "Error de autentificación", "error");
                        await this.refreshToken();
                        this.buscarFiltro();
                    } else {
                        this.$swal("error", "Error", "error");
                    }
                });
        },

        toogle() {
            this.crear = true;
            this.clean();
        },
        closeCrear() {
            this.crear = false;
            this.editarPreAlerta = false;
            this.clean();
            this.changePage();
        },
        clean() {
            this.preAlerta = {
                tracking: "",
                cliente_id: "",
                tienda_id: "",
                contenido: "",
                destinatario_id: "",
                valor_comercial: 100,
                valor_asegurado: 100,
                posicion_arancelaria_id: 2,
                orden_de_compra: "",
                factura: [],
                notas: "",
                estado: true,
                retener: false
            };
        },
        getPosiciones_arancelarias: function() {
            api().get(`/api/casillero/posiciones_arancelarias`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    }
                })
                .then(response => {
                    const { data } = response;
                    this.posiciones = data.datos;
                }).catch(async (error) => {
                    if (error.response.status === 401) {
                        this.$swal("error", "Error de autentificación", "error");
                        await this.refreshToken();
                        this.buscarFiltro();
                    } else {
                        this.$swal("error", "Error", "error");
                    }
                });
        },
        getTiendas: function() {
            api().get(`/api/casillero/tiendas`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    }
                })
                .then(response => {
                    const { data } = response;
                    this.tiendas = data.datos;
                    this.arrayTiendas = this.tiendas.map(function(obj) {
                        return obj.razon_social;
                    });
                    this.checkTienda = true;
                }).catch(async (error) => {
                    if (error.response.status === 401) {
                        this.$swal("error", "Error de autentificación", "error");
                        await this.refreshToken();
                    } else {
                        this.$swal("error", "Error", "error");
                    }
                });
        },
        closeModal() {
            this.showModal = false;
            if (this.responseMessage == "Prealerta creada exitosamente") {
                this.crear = false;
                this.clean();
                this.changePage();
            }
            if (this.responseMessage == "Prealerta actualizada exitosamente") {
                this.crear = false;
                this.clean();
                this.editarDestinatario = false;
                this.editarPreAlerta = false;
            }
        },
        submit() {
            this.submitted = true;

            this.$validator.validate().then(valid => {
                if (valid) {
                    this.showModal = true;

                    if (this.preAlerta.posicion_arancelaria_id) {
                        var posicion_arancelaria_id = this.preAlerta
                            .posicion_arancelaria_id;
                    } else {
                        posicion_arancelaria_id = "";
                    }
                    if (this.selectTienda) {
                        const indexTienda = this.tiendas.findIndex(
                            x => x.razon_social === this.selectTienda
                        );
                        if (this.tiendas[indexTienda]) {
                            var tienda_id = this.tiendas[indexTienda].id;
                        } else {
                            tienda_id = 0;
                            var tienda_nombre = this.selectTienda;
                        }
                    } else {
                        tienda_id = "";
                    }

                    if (this.preAlerta.destinatario_id) {
                        var destinatario_id = this.preAlerta.destinatario_id;
                    } else {
                        destinatario_id = "";
                    }

                    let datos = this.preAlerta;
                    datos["posicion_arancelaria_id"] = posicion_arancelaria_id;
                    datos["tienda_id"] = tienda_id;
                    datos["tienda_nombre"] = tienda_nombre;
                    datos["destinatario_id"] = destinatario_id;
                    datos["cliente_id"] = localStorage.cliente_id;

                    if (this.editarPreAlerta) {
                        var url = `/api/casillero/prealertas/${this.id}`;
                    } else if (!this.editarPreAlerta) {
                        url = `/api/casillero/prealertas`;
                    }

                    var form_data = new FormData();

                    form_data.append("tracking", datos["tracking"]);
                    form_data.append("cliente_id", datos["cliente_id"]);
                    form_data.append("tienda_id", datos["tienda_id"]);
                    form_data.append("tienda_nombre", datos["tienda_nombre"]);
                    form_data.append("contenido", datos["contenido"]);
                    form_data.append("destinatario_id", datos["destinatario_id"]);
                    form_data.append("valor_comercial", datos["valor_comercial"]);
                    form_data.append("valor_asegurado", datos["valor_asegurado"]);
                    form_data.append(
                        "posicion_arancelaria_id",
                        datos["posicion_arancelaria_id"]
                    );
                    form_data.append("orden_de_compra", datos["orden_de_compra"]);
                    form_data.append("factura", datos["factura"]);
                    form_data.append("notas", datos["notas"]);
                    if (datos["estado"]) {
                        form_data.append("estado", 1);
                    } else if (!datos["estado"]) {
                        form_data.append("estado", 0);
                    }
                    if (datos["retener"]) {
                        form_data.append("retener", 1);
                    } else if (!datos["retener"]) {
                        form_data.append("retener", 0);
                    }

                    api().post(url, form_data, {
                            headers: {
                                Accept: "application/json",
                                Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                            }
                        })
                        .then(response => {
                            const { data } = response;
                            this.responseMessage = data["msg"];
                            if (this.editarPreAlerta) {
                                this.$swal(
                                    "Proceso completado",
                                    "Prealerta actualizada exitosamente",
                                    "success"
                                );
                            } else {
                                this.$swal(
                                    "Proceso completado",
                                    "Prealerta creada exitosamente",
                                    "success"
                                );
                            }
                            // this.clean();
                            this.changePage();
                        }).catch(async (error) => {
                            if (error.response.status === 401) {
                                this.$swal("error", "Error de autentificación", "error");
                                await this.refreshToken();
                                this.submit();
                            } else {
                                for (let err in error.response.data["errores"]) {
                                    this.responseMessage += ". ";
                                    this.responseMessage += error.response.data["errores"][err];
                                }
                                this.showModal = true;
                                this.checked = true;
                                this.crear = false;
                                this.clean();
                            }
                        });
                }
            });
        },
        getDestinatarios() {
            api().get(`/api/casillero/despachos/preliquidaciones/infoform`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    }
                })
                .then(response => {
                    const { data } = response;
                    this.destinatarios = data["datos"]["destinatarios"];
                });
        },
        async refreshToken() {
            const currentEnvironment = getSelectedEnvironment();
            let tokenExpiryDate = this.$store.getters.tokensExpiry;
            const now = new Date();
            if (new Date(tokenExpiryDate) < now) {
                this.logout();
            } else {
                this.$validator.validate().then(async valid => {
                    if (valid) {
                        let datos = {
                            grant_type: "refresh_token",
                            client_id: currentEnvironment.client_id,
                            client_secret: currentEnvironment.client_secret,
                            refresh_token: localStorage.getItem('refresh_token')
                        };
                        try {
                            const response = await api().post(`/oauth/token`, datos, {
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*"
                                },
                            });
                            const { data } = response;
                            localStorage.access_token = data.access_token;
                            localStorage.refresh_token = data.refresh_token;
                            localStorage.token_type = data.token_type;

                            this.$store.commit("update_auth_tokens", data);
                        } catch (error) {
                            this.$swal(
                                "Error",
                                "Ocurrio un error",
                                "error"
                            );
                        }
                    }
                });
            }
        },
        logout() {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("token_type");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_name");
            localStorage.removeItem("cliente_id");
            localStorage.removeItem("datosUsuario");
            localStorage.removeItem("datosCliente");
            localStorage.removeItem("tokensExpiry");

            this.$cookies.remove("clienteId");

            window.location.href = `${process.env.VUE_APP_URL}/`;
        },
    },
    data() {
        return {
            checked: false,
            cargando: false,
            crear: false,
            checkTienda: false,
            editarPreAlerta: false,
            submitted: false,
            showModal: false,
            responseMessage: "",
            file: [],
            destinatarios: "",
            selectDestinatario: "",
            tiendas: null,
            arrayTiendas: "",
            selectTienda: null,
            selectPosicion: null,
            posiciones: "",
            preAlerta: {
                tracking: "",
                cliente_id: "" /*localStorage.cliente_id*/ ,
                tienda_id: "",
                tienda_nombre: "",
                contenido: "",
                destinatario_id: "",
                valor_comercial: 100,
                valor_asegurado: 100,
                posicion_arancelaria_id: 2,
                orden_de_compra: "",
                factura: "",
                notas: "",
                estado: true,
                retener: false
            },
            preAlertas: "",
            registros: 15,
            actualPagina: 1,
            totalPaginas: 0,
            totalRegistros: 0,
            buscar: {
                fechaIni: "",
                fechaFin: "",
                estado: 1,
                pageSize: 15
            }
        };
    },
    mounted: async function() {
        await this.refreshToken();
        this.getDestinatarios();
        this.getTiendas();
        this.getPosiciones_arancelarias();
        this.changePage();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contenido {
    padding: 10px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #b4141d;
}

.table-color {
    color: black !important;
    background: aliceblue !important;
}

.fondo-rojo {
    background: #b4141d !important;
}
</style>