<template>
    <div v-show="logueado" id="solicitardespacho" class="container">
        <h2>Solicitar despachos</h2>
    
        <div id="solicitar" class="separador contenido" v-if="solicitarDiv">
            <div class="panel panel-default" style>
                <div class="table-responsive app-table">
                    <table class="table table-striped b-t table-color">
                        <thead class="app-bkg-color-f5">
                            <tr style="font-size: 11px">
                                <th>F. Recibido</th>
                                <th>Tracking</th>
                                <th>Estado</th>
                                <th>Contenido</th>
                                <th>Tienda</th>
                                <th>HAWB</th>
                                <th>Servicio</th>
                                <th>Peso Lbs</th>
                                <th>Orden Compra (P.O)</th>
                                <th>Vl. Asegurado</th>
                                <th>Vl. Declarado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="consolidada in consolidadas" v-bind:key="consolidada.id" style="font-size: 11px">
                                <td style="width: 5%;font-size: 11px" class="ng-binding">{{consolidada.fecha_recibido}}</td>
                                <td style="width: 13%;font-size: 11px;white-space: normal;word-break: break-all" class="ng-binding">{{consolidada.tracking}}</td>
                                <td style="width: 10%;font-size: 11px;white-space: normal;word-break: break-all" class="ng-binding">{{consolidada.estado}}</td>
                                <td style="width: 13%;font-size: 11px;white-space: normal;word-break: break-all" class="ng-binding">{{consolidada.contenido}}</td>
                                <td style="width: 10%;font-size: 11px;white-space: normal;word-break: break-all" class="ng-binding">{{consolidada.tienda}}</td>
                                <td style="width: 10%" class="ng-binding">{{consolidada.hawb}}</td>
                                <td style="width: 10%;font-size: 11px;white-space: normal;word-break: break-all" class="ng-binding">{{consolidada.servicio}}</td>
                                <td style="width: 5%" class="ng-binding">{{consolidada.peso}}</td>
                                <td style="width: 10%" class="ng-binding">{{consolidada.orden_de_compra}}</td>
                                <td style="width: 10%" class="ng-binding">
                                    <input v-model="consolidada.valor_asegurado" />
                                </td>
                                <td style="width: 10%" class="ng-binding">
                                    <input v-model="consolidada.valor_declarado" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <form @submit.prevent="preliquidar">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label>Destinatarios *</label>
                            <select class="form-control" v-model="selectDestinatario" name="destinatario" v-validate="'required'" :class="{ 'is-invalid': submittedPreliquidar && errors.has('destinatario') }">
                                <option
                                  v-for="destinatario in destinatarios"
                                  :value="destinatario.id"
                                  v-bind:key="destinatario.id"
                                >{{destinatario.nombre_completo}}</option>
                              </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Medio de Pago *</label>
                            <select class="form-control" v-model="selectMedio" name="mediosDePago" v-validate="'required'" :class="{ 'is-invalid': submittedPreliquidar && errors.has('mediosDePago') }">
                                <option
                                  v-for="medioPago in mediosDePago"
                                  :value="medioPago.id"
                                  v-bind:key="medioPago.id"
                                >{{medioPago.descripcion}}</option>
                              </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Forma de Pago *</label>
                            <select class="form-control" v-model="selectForma" name="formasDePago" v-validate="'required'" :class="{ 'is-invalid': submittedPreliquidar && errors.has('formasDePago') }">
                                <option
                                  v-for="formaPago in formasDePago"
                                  :value="formaPago.id"
                                  v-bind:key="formaPago.id"
                                >{{formaPago.descripcion}}</option>
                              </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Observaciones *</label>
                            <textarea class="form-control" type="text" v-model="observaciones"></textarea>
                        </div>
                    </div>
                    <button class="btn btn-dark fondo-rojo"><b-icon icon="arrow-right-square-fill" aria-hidden="true"></b-icon> Registrar</button>
                </form>
            </div>
        </div>
    
        <br />
    
        <b-modal title="Eliminar Preliquidación" size="sm" ok-variant="dark" v-model="modalShow">
            <strong>{{responseMessage}}</strong>
    
            <template v-slot:modal-footer="{ ok, cancel}">
                        <b-button variant="dark" @click="anularPreliquidacion()">SI</b-button>
                        <b-button variant="dark" @click="cancel()">NO</b-button>
</template>
    </b-modal>

    <div id="listar" class="separador contenido" v-if="listar">
      <br />
      <br />

      <div class="panel panel-default">
        <h3 style="margin-top: -40px"> Paquetes disponibles </h3>
        <br/>
        <div class="wrapper">
          <form class="form ng-pristine ng-valid" @submit.prevent="submit">
            <div class="row">
              <div class="col-md-12 col-sm-4 col-xs-6" style="max-width: 95%;">
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <a
                      class="btn btn-dark fondo-rojo"
                      v-on:click="solicitar"
                      href="#"
                      v-if="consolidadas.length > 0"
                    ><b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon> Solicitar</a>
                  </div>
                  <div class="form-group col-md-1">
                    <label>Registros</label>
                  </div>
                  <div class="form-group col-md-1">
                    <select
                      class="form-control"
                      name="registros"
                      v-validate="'required'"
                      :class="{ 'is-invalid': submitted && errors.has('registros') }"
                      v-model="registros"
                      @change="changePage()"
                    >
                      <option selected="selected" value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-3 col-xs-12"></div>
              <div class="table-responsive app-table">
                <table class="table table-striped b-t table-color">
                  <thead class="app-bkg-color-f5">
                    <tr style="font-size: 12px">
                      <th>Consolidar</th>
                      <th>Fecha recibido</th>
                      <th>Tracking</th>
                      <th>Estado</th>
                      <th>HAWB</th>
                      <th>Contenido</th>
                      <th>Tienda</th>
                      <th>Servicio</th>
                      <th>Peso</th>
                      <th>Orden de Compra</th>
                      <th>Destinatario</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="consolidada in items"
                      v-bind:key="consolidada.id"
                      style="font-size: 12px"
                    >
                      <td>
                        <label class="form-checkbox">
                          <input
                            type="checkbox"
                            v-model="consolidada.consolidar"
                            @change="consolidar(consolidada)"
                          />
                          <i class="form-icon"></i>
                        </label>
                      </td>
                      <td
                        style="width: 5%;font-size: 11px"
                        class="ng-binding"
                      >{{consolidada.fecha_recibido}}</td>
                      <td
                        style="width: 13%;font-size: 11px;white-space: normal;word-break: break-all"
                        class="ng-binding"
                      >{{consolidada.tracking}}</td>
                      <td
                        style="width: 10%;font-size: 11px;white-space: normal;word-break: break-all"
                        class="ng-binding"
                      >{{consolidada.estado}}</td>
                      <td
                        style="width: 12%;font-size: 11px;white-space: normal;word-break: break-all"
                        class="ng-binding"
                      >{{consolidada.hawb}}</td>
                      <td
                        style="width: 10%;font-size: 11px;white-space: normal;word-break: break-all"
                        class="ng-binding"
                      >{{consolidada.contenido}}</td>
                      <td style="width: 10%" class="ng-binding">{{consolidada.tienda}}</td>
                      <td
                        style="width: 10%;font-size: 11px;white-space: normal;word-break: break-all"
                        class="ng-binding"
                      >{{consolidada.servicio}}</td>
                      <td style="width: 5%" class="ng-binding">{{consolidada.peso}}</td>
                      <td style="width: 10%" class="ng-binding">{{consolidada.orden_de_compra}}</td>
                      <td style="width: 10%" class="ng-binding">{{consolidada.destinatario}}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-show="cargandoDisponibles" class="text-center app-bkg-color-f5 app-no-data-table b-t" >
                  <b-col class="mb-3">
                    <h3> <p>Cargando...</p> </h3>
                    <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="4"></b-icon>
                  </b-col>
                </div>
              </div>

              <div class="panel-footer">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRegistros"
                  :per-page="registros"
                  v-on:input="changePage"
                ></b-pagination>
              </div>
            </div>
          </form>
        </div>
        <br />
      </div>
    </div>

    <br />

    <div id="preliquidacionesDiv" v-if="preliquidacionesDiv">
      <br />
      <br />
      <h3>Solicitudes realizadas</h3>
      <br />
      <div class="panel panel-default">
        <div class="wrapper separador contenido">
          <form class="form ng-pristine ng-valid">
            <div class="row">
              <div class="col-md-12 col-sm-4 col-xs-6" style="max-width: 95%;">
                <div class="form-row">
                  <div class="form-group col-md-1">
                    <small>ID Solicitud</small>
                  </div>
                  <div class="form-group col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="buscar.preliquidacion_id"
                    />
                  </div>
                  <div class="form-group col-md-1">
                    <small>Desde</small>
                  </div>
                  <div class="form-group col-md-3">
                    <input
                      class="form-control form-control-sm"
                      type="date"
                      v-model="buscar.fechaDesde"
                    />
                  </div>
                  <div class="form-group col-md-1">
                    <small>Hasta</small>
                  </div>
                  <div class="form-group col-md-3">
                    <input
                      class="form-control form-control-sm"
                      type="date"
                      v-model="buscar.fechaHasta"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-1">
                    <small>Medio de Pago</small>
                  </div>
                  <div class="form-group col-md-3">
                    <select class="form-control" v-model="selectMedioFiltro" name="mediosDePago">
                      <option
                        v-for="medioPago in mediosDePago"
                        :value="medioPago.id"
                        v-bind:key="medioPago.id"
                      >{{medioPago.descripcion}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-1">
                    <small>Forma de Pago</small>
                  </div>
                  <div class="form-group col-md-3">
                    <select class="form-control" v-model="selectFormaFiltro" name="formasDePago">
                      <option
                        v-for="formaPago in formasDePago"
                        :value="formaPago.id"
                        v-bind:key="formaPago.id"
                      >{{formaPago.descripcion}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-1">
                    <small>Destinatario</small>
                  </div>
                  <div class="form-group col-md-3">
                    <select
                      class="form-control"
                      v-model="selectDestinatarioFiltro"
                      name="destinatario"
                    >
                      <option
                        v-for="destinatario in destinatarios"
                        :value="destinatario.id"
                        v-bind:key="destinatario.id"
                      >{{destinatario.nombre_completo}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-10"></div>
                  <div class="form-group col-md-2">
                    <a
                      href="#"
                      class="btn btn-dark btn-block fondo-rojo"
                      v-on:click="consultarPreliquidaciones"
                    >
                      <b-icon icon="search" aria-hidden="true"></b-icon>Buscar
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-3 col-xs-12"></div>
            </div>
          </form>
        </div>
        <br />
        <div class="table-responsive app-table separador contenido-tabla">
          <table class="table table-striped b-t table-color" id="my-table">
            <thead>
              <tr style="font-size: 12px">
                <th>Opciones</th>
                <th>#</th>
                <th>Fecha</th>
                <th>Destinatario</th>
                <th>Guía</th>
                <th>Guías agrupadas</th>
                <th>Medio de Pago / Forma de Pago</th>
                <th>TRM</th>
                <th>Valor</th>
                <th>Soporte de pago</th>
                <th>Conciliada / Autorizada</th>
                <th>Observación</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="preliquidacion in preliquidaciones" v-bind:key="preliquidacion.id" style="font-size: 12px">
                <td nowrap>
                  <button
                    class="btn btn-light padder-xs"
                    v-on:click="imprimirPDF(preliquidacion.id)"
                  >
                    <b-icon icon="printer-fill" aria-hidden="true" title="Imprimir"></b-icon>
                  </button>
                  <button class="btn btn-light padder-xs" v-on:click="abrirSoporte(preliquidacion)" title="Ver detalle">
                    <b-icon icon="upload" aria-hidden="true"></b-icon>
                  </button>
                  <b-button
                    variant="light"
                    @click="modalShow = !modalShow ; modalAnular(preliquidacion.id)"
                    title="Anular"
                  >
                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                  </b-button>
                </td>
                <td>{{preliquidacion.id}}</td>
                <td class="ng-binding">{{preliquidacion.created_at}}</td>
                <td>{{preliquidacion.destinatario.nombre_completo}}</td>
                <td class="ng-binding">
                  <div v-for="paq in preliquidacion.paquetes" v-bind:key="paq.id">{{paq.hawb}}</div>
                </td>
                <td class="ng-binding" v-if="preliquidacion.paquetes.length > 1">Si</td>
                <td class="ng-binding" v-else>No</td>
                <td
                  class="ng-binding"
                >{{preliquidacion.medio_de_pago.descripcion}} / {{preliquidacion.forma_de_pago.descripcion}}</td>
                <td class="ng-binding">{{preliquidacion.trm.valor}}</td>
                <td class="ng-binding">{{preliquidacion.valor}}</td>
                <td class="ng-binding" v-if="preliquidacion.fecha_comprobante_de_pago">Enviado: {{preliquidacion.fecha_comprobante_de_pago}}</td>
                <td class="ng-binding" v-if="!preliquidacion.fecha_comprobante_de_pago">No Enviado</td>
                <td class="ng-binding" v-if="preliquidacion.estado_conciliacion == 1">Pendiente por pago</td>
                <td class="ng-binding" v-if="preliquidacion.estado_conciliacion == 2">Pagada</td>
                <td class="ng-binding" v-if="preliquidacion.estado_conciliacion == 3">Conciliada</td>
                <td class="ng-binding">{{preliquidacion.observacion}}</td>
              </tr>
            </tbody>
          </table>
          <div v-show="cargandoRealizadas" class="text-center app-bkg-color-f5 app-no-data-table b-t" >
            <b-col class="mb-3">
              <h3> <p>Cargando...</p> </h3>
              <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="4"></b-icon>
            </b-col>
          </div>
        </div>
        <div class="panel-footer">
          <b-pagination
            v-model="actualPaginaPre"
            :total-rows="totalRegistrosPre"
            :per-page="registrosPre"
            align="right"
            v-on:input="consultarPreliquidaciones"
          ></b-pagination>
        </div>
      </div>
    </div>

    <br />

    <div id="solicitudEnvio" class="separador contenido" v-if="solicitudEnvio">
      <div class="panel-body">
        <div class="table-responsive app-table">
          <h3>
            <div class="ng-binding">SOLICITUD DE ENVIO: {{objetoPreliquidacion.id}}</div>
          </h3>

          <h3 class="ng-binding">Observaciones: {{objetoPreliquidacion.observacion}}</h3>
          <div class="form-group">
            <p>
              <button
                type="button"
                v-on:click="imprimirPDF(objetoPreliquidacion.id)"
                class="btn btn-dark fondo-rojo"
                style="float: right;"
              >
                <b-icon icon="printer-fill" aria-hidden="true"></b-icon>Imprimir
              </button>
              &nbsp;
            </p>
          </div>
          <table class="table table-striped b-t table-color">
            <thead class="app-bkg-color-f5" style>
              <tr>
                <th>Fecha recibido</th>
                <th>Tracking</th>
                <th>Estado</th>
                <th>Contenido</th>
                <th>Tienda</th>
                <th>Guía</th>
                <th>Servicio</th>
                <th>Peso lbs</th>
                <th>Orden de compra (P.O)</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="paquete in objetoPreliquidacion.paquetes"
                v-bind:key="paquete.id"
                class="ng-scope"
                style
              >
                <td class="ng-binding">{{paquete.created_at}}</td>
                <td class="ng-binding">{{paquete.tracking_tienda_casillero}}</td>
                <td class="ng-binding">{{paquete.estado}}</td>
                <td class="ng-binding">{{paquete.contenido}}</td>
                <td class="ng-binding">{{paquete.envio_casillero ? paquete.envio_casillero.tienda.razon_social : ''}}</td>
                <td class="ng-binding">{{paquete.hawb}}</td>
                <td class="ng-binding">{{paquete.envio_casillero ? paquete.envio_casillero.servicio.descripcion : ''}}</td>
                <td class="ng-binding">{{paquete.peso}}</td>
                <td class="ng-binding">{{paquete.orden_de_compra}}</td>
                <td></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <div align="right">
                    <strong>TOTAL LBS</strong>
                  </div>
                </td>
                <td class="ng-binding">{{objetoPreliquidacion.total_lbs}}</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <div align="right">
                    <strong>TRANSPORTE</strong>
                  </div>
                </td>
                <td class="ng-binding">$ {{objetoPreliquidacion.valor}}</td>
                <td>USD</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  <div align="right">
                    <strong>TRM</strong>
                  </div>
                </td>
                <td class="ng-binding">$ {{objetoPreliquidacion.trm_valor}}</td>
                <td>COP</td>
              </tr>
              <tr>
                <td colspan="7">
                  <div align="right">
                    <h6
                      style="color: red"
                    >Por favor no cancele este monto hasta que hayamos validado que la liquidación haya sido correcta, una vez verificado recibirás la factura en tu correo con el valor real y final.</h6>
                  </div>
                </td>
                <td>
                  <div align="right">
                    <strong>VALOR ESTIMADO</strong>
                  </div>
                </td>
                <td class="ng-binding">$ {{ objetoPreliquidacion.valor_moneda_cliente }}</td>
                <td>COP</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-6">
            <br />
            <div class="form-inline" style="margin-bottom: 2rem;">
              <label for="soporte_pago" class>Adjuntar Comprobante de Pago</label>
  
              <b-form-file
                v-model="soportePago"
                :state="Boolean(soportePago)"
                placeholder="Escoja un archivo"
                drop-placeholder="Arrastre el archivo aquí.."
                required
              ></b-form-file>
              <div class="mt-3">Selected file: {{ soportePago ? soportePago.name : '' }}</div>
            </div>
          </div>
        </div>

        <button class="btn btn-dark fondo-rojo" v-on:click="cerrarSolicitud" title="Cancelar"><b-icon icon="arrow-left-square-fill" aria-hidden="true"></b-icon> Volver</button>
        <a
          href="#"
          class="btn btn-dark fondo-rojo"
          v-on:click="enviarSoporte(objetoPreliquidacion.id)"
          title="Enviar Soporte de Pago"
        ><b-icon icon="arrow-right-square-fill" aria-hidden="true"></b-icon> Enviar Soporte de Pago</a>
      </div>
    </div>
  </div>
</template>

<script>
import { getSelectedEnvironment, api } from "../plugins/api_conect";

export default {
    computed: {
        logueado() {
            return this.$store.getters.loggedIn;
        }
    },
    methods: {
        imprimirPDF(id) {
            this.print = true;
            this.descargarPDF(id);
        },
        descargarPDF(id) {
            api().get(`/api/casillero/despachos/preliquidaciones/impresion-pdf/${id}`, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Accept': 'application/pdf',
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    }
                })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf',
                    });
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    if (this.print == true) {
                        var w = window.open(url);
                        w.print();
                    } else if (this.print == false) {
                        a.href = url;
                        a.download = "solicitudEnvio" + id;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                    }
                    a.remove();
                    this.print = false;
                })
                .catch(async (error) => {
                    console.error(error);
                    if (error.response && error.response.status === 401) {
                        await this.refreshToken();
                        this.descargarPDF(id);
                    } else {
                        this.$swal("error", 'Error', "error");
                    }
                });
        },
        enviarFactura(id) {
            this.responseMessage = "Un momento, cargando...";
            this.showModal = true;

            api().get(`/api/casillero/despachos/preliquidaciones/enviarfacturaemail/${id}`, {
                    headers: {
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    }
                })
                .then(response => {
                    const { data } = response;
                    this.responseMessage = data["msg"];
                    this.checked = true;
                })
                .catch(async (error) => {
                    this.$swal("error", error, "error");
                    await this.refreshToken();
                    this.enviarFactura(id);
                });
        },
        abrirSoporte(preliquidacion) {
          console.log(preliquidacion);
            this.objetoPreliquidacion = preliquidacion;
            this.preliquidacionesDiv = false;
            this.solicitarDiv = false;
            this.listar = false;
            this.solicitudEnvio = true;
        },
        cleanSolicitar() {
            this.selectDestinatario = "";
            this.selectMedio = "";
            this.selectForma = "";
        },
        enviarSoporte(id) {
            this.responseMessage = "Un momento, cargando...";
            this.showModal = true;
            this.checked = false;

            var form_data = new FormData();

            form_data.append("soportePago", this.soportePago);

            api().post(`/api/casillero/subirsoportedepago/${id}`, form_data, {
                    headers: {
                        Accept: "application/json",
                        'content-type': 'multipart/form-data',
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    },
                })
                .then(response => {
                    const { data } = response;
                    this.responseMessage = data["msg"];
                    if (!data["error"]) {
                        if (data["errores"]) {
                            for (let error in data["errores"]) {
                                if (
                                    data["errores"][error] == "The soporte pago failed to upload."
                                ) {
                                    this.responseMessage =
                                        "El tamaño del archivo no puede ser mayor a 1 Mb.";
                                } else {
                                    this.responseMessage += ". ";
                                    this.responseMessage += data["errores"][error];
                                }
                            }
                        }
                        this.showModal = true;
                        this.checked = true;
                        this.$swal('Proceso completado', data["msg"], 'success');
                        window.location.href = `${process.env.VUE_APP_URL}/solicitar-despachos`;
                    }
                }).catch(async (error) => {
                    if (error.response && error.response.status === 401) {
                        await this.refreshToken();
                        this.enviarSoporte(id);
                    }
                });
        },
        async refreshToken() {
            const currentEnvironment = getSelectedEnvironment();
            let tokenExpiryDate = this.$store.getters.tokensExpiry;
            const now = new Date();
            if (new Date(tokenExpiryDate) < now) {
                this.logout();
            } else {
                let datos = {
                    grant_type: "refresh_token",
                    client_id: currentEnvironment.client_id,
                    client_secret: currentEnvironment.client_secret,
                    refresh_token: localStorage.getItem('refresh_token')
                };
                try {
                    const response = await api().post(`/oauth/token`, datos, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*"
                        },
                    });
                    const { data } = response;
                    localStorage.access_token = data.access_token;
                    localStorage.refresh_token = data.refresh_token;
                    localStorage.token_type = data.token_type;

                    this.$store.commit("update_auth_tokens", data);
                } catch (error) {
                    this.$swal(
                        "Error",
                        "Ocurrio un error",
                        "error"
                    );
                }
            }
        },
        logout() {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("token_type");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_name");
            localStorage.removeItem("cliente_id");
            localStorage.removeItem("datosUsuario");
            localStorage.removeItem("datosCliente");
            localStorage.removeItem("tokensExpiry");

            this.$cookies.remove("clienteId");

            window.location.href = `${process.env.VUE_APP_URL}/`;
        },
        cerrarSolicitud() {
            this.changePage();
            this.consultarPreliquidaciones();
            this.solicitudEnvio = false;
            this.preliquidacionesDiv = true;
        },
        anularPreliquidacion() {
            this.anular = false;

            this.responseMessage = "Un momento, cargando...";
            this.showModal = true;
            api().delete(`/api/casillero/despachos/preliquidaciones/${this.idAnular}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    }
                })
                .then(response => {
                    const { data } = response;
                    if (!data["error"]) {
                        if (!data["errores"]) {
                            this.responseMessage = data["msg"];
                        } else {
                            this.responseMessage = data["error"];
                        }
                        this.showModal = true;
                        this.checked = true;
                        this.modalShow = false;
                    }
                }).catch(async (error) => {
                    if (error.response && error.response.status === 401) {
                        await this.refreshToken();
                        this.anularPreliquidacion();
                    }
                });
        },
        modalAnular(id) {
            this.responseMessage =
                "Esta seguro de borrar la preliquidación # " + id + " ?";
            this.idAnular = id;
        },
        getDatos() {
            api().get(`/api/casillero/despachos/preliquidaciones/infoform`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    }
                })
                .then(response => {
                    const { data } = response;
                    this.mediosDePago = data["datos"]["mediosDePago"];
                    this.formasDePago = data["datos"]["formasDePago"];
                    this.destinatarios = data["datos"]["destinatarios"];
                    this.selectDestinatario = data["datos"]["destinatarios"][0].id;

                    //eliminar forma de pago COD
                    this.formasDePago.forEach(formaPago => {
                        if (formaPago.descripcion == "CONTRA ENTREGA (COD)") {
                            let index = this.formasDePago.indexOf(formaPago)
                            this.formasDePago.splice(index, 1)
                        }
                    });
                });
        },
        consultarPreliquidaciones() {
            this.showModal = false;
            this.solicitarDiv = false;
            this.listar = true;
            this.preliquidacionesDiv = true;
            this.preliquidaciones = [];
            this.cargandoRealizadas = true;

            const datos = {
                pageSize: this.registrosPre,
                paginaActual: this.actualPaginaPre,
                preliquidacion_id: this.buscar.preliquidacion_id,
                fechaDesde: this.buscar.fechaDesde,
                fechaHasta: this.buscar.fechaHasta,
                medio_de_pago_id: this.selectMedioFiltro,
                forma_de_pago_id: this.selectFormaFiltro,
                destinatario_id: this.selectDestinatarioFiltro
            };
            api().post(`/api/casillero/despachos/preliquidaciones/solrealizadas?page=${this.actualPaginaPre}`, datos, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    },
                })
                .then(response => {
                    const { data } = response;
                    this.cargandoRealizadas = false;
                    this.preliquidaciones = data["datos"]["data"];
                    this.totalRegistrosPre = data["datos"]["total"];
                });
        },
        preliquidar: function() {
            this.submittedPreliquidar = true;
            this.showModal = false;
            this.responseMessage = "Espere un momento, cargando";

            this.$validator.validate().then(valid => {
                if (valid) {
                    this.showModal = true;

                    const datos = {
                        cliente_id: localStorage.cliente_id,
                        user_id: localStorage.user_id,
                        paquetes: JSON.stringify(this.consolidadas),
                        pago_id: this.selectMedio,
                        forma_de_pago_id: this.selectForma,
                        destinatario_id: this.selectDestinatario,
                        observacion: this.observaciones
                    };

                    api().post(`/api/casillero/despachos/preliquidaciones`, datos, {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                            },
                        })
                        .then(response => {
                            const { data } = response;
                            this.responseMessage = data["msg"];
                            if (!data["error"]) {
                                if (!data["errores"]) {
                                    if (
                                        data["msg"] ==
                                        "Se preliquidaron los paquetes correctamente."
                                    ) {
                                        this.objetoPreliquidacion = data["datos"]["preliquidacion"];
                                        this.solicitudEnvio = true;
                                        this.preliquidacionesDiv = false;
                                        this.solicitarDiv = false;
                                        this.consolidadas = [];
                                        this.cleanSolicitar();
                                        this.showModal = true;
                                        this.checked = true;
                                    }
                                } else {
                                    for (let error in data["errores"]) {
                                        this.responseMessage += ". ";
                                        this.responseMessage += data["errores"][error];
                                    }
                                }
                                this.showModal = true;
                                this.checked = true;
                            }
                        }).catch(async (error) => {
                            if (error.response && error.response.status === 401) {
                                await this.refreshToken();
                                this.preliquidar();
                            }
                        });
                }
            });
        },
        solicitar() {
            this.solicitarDiv = true;
            this.listar = false;
            this.preliquidacionesDiv = false;
        },
        consolidar(object) {
            if (object.consolidar == true) {
                if (!this.consolidadas.find(element => element.id == object.id)) {
                    this.consolidadas.push(object);
                }
            } else if (object.consolidar == false) {
                for (var i = this.consolidadas.length - 1; i >= 0; i--) {
                    if (this.consolidadas[i].id === object.id) {
                        this.consolidadas.splice(i, 1);
                    }
                }
            }
        },
        changePage() {
            this.items = [];
            this.cargandoDisponibles = true;
            let datos = {
                pageSize: this.registros
            };
            api().post(`/api/casillero/despachos/preliquidaciones/paqsdisponibles?page=${this.currentPage}`, datos, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem('token_type') + " " + localStorage.getItem('access_token')
                    },
                })
                .then(response => {
                    const { data } = response;
                    this.responseMessage = data["msg"];
                    if (!data["error"]) {
                        this.cargandoDisponibles = false;
                        if (!data["errores"]) {
                            this.items = data["datos"]["data"];
                            this.totalRegistros = data["datos"]["total"];
                        } else {
                            this.showModal = true;
                            this.checked = true;
                            for (let error in data["errores"]) {
                                this.responseMessage += ". ";
                                this.responseMessage += data["errores"][error];
                            }
                        }
                    }
                }).catch(async (error) => {
                    if (error.response && error.response.status === 401) {
                        await this.refreshToken();
                        this.changePage();
                    }
                });
        },

        toogle() {
            this.crear = true;
        },
        closeCrear() {
            this.crear = false;
            this.clean();
            this.changePage();
        },
        closeModal() {
            this.showModal = false;

            if (
                this.responseMessage == "La preliquidacion fue anulada correctamente."
            ) {
                this.consultarPreliquidaciones();
                this.changePage();
            } else if (
                this.responseMessage ==
                "Se ha subido el soporte de pago para la solicitud de despacho de manera correcta"
            ) {
                this.cleanSolicitar();
                this.solicitudEnvio = false;
                this.consultarPreliquidaciones();
                this.changePage();
            }
            this.checked = false;
        },
        submit: function() {}
    },
    data() {
        return {
            print: false,
            cargandoDisponibles: false,
            cargandoRealizadas: false,
            file: null,
            modalShow: false,
            soportePago: [],
            objetoPreliquidacion: "",
            solicitudEnvio: false,
            idAnular: "",
            anular: false,
            preliquidaciones: "",
            registrosPre: 10,
            actualPaginaPre: 1,
            totalPaginasPre: 0,
            totalRegistrosPre: 0,
            preliquidacionesDiv: false,
            observaciones: "",
            mediosDePago: "",
            selectMedio: "",
            selectMedioFiltro: "",
            formasDePago: [],
            selectForma: "",
            selectFormaFiltro: "",
            destinatarios: "",
            selectDestinatario: "",
            selectDestinatarioFiltro: "",
            listar: true,
            solicitarDiv: false,
            submitted: false,
            submittedPreliquidar: false,
            showModal: false,
            checked: false,
            responseMessage: "",
            registros: 10,
            actualPagina: 1,
            totalPaginas: 0,
            totalRegistros: 0,
            buscar: {
                fechaDesde: "",
                fechaHasta: "",
                preliquidacion_id: ""
            },
            perPage: 3,
            currentPage: 1,
            items: [],
            fields: [
                "consolidar",
                "#",
                { key: "fecha_recibido", label: "Fecha recibido" },
                { key: "tracking", label: "Tracking" },
                { key: "estado", label: "Estado" },
                { key: "hawb", label: "HAWB" },
                { key: "contenido", label: "Contenido" },
                { key: "tienda", label: "Tienda" },
                { key: "servicio", label: "Servicio" },
                { key: "peso", label: "Peso" },
                { key: "orden_de_compra", label: "Orden de Compra" },
                { key: "destinatario", label: "Destinatario" }
            ],
            consolidadas: [],
            hawb: ""
        };
    },
    mounted: function() {
        this.refreshToken();
        this.changePage();
        this.consultarPreliquidaciones();
        this.getDatos();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contenido {
    padding: 20px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #b4141d;
}

.contenido-tabla {
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #b4141d;
}

.table-color {
    color: black !important;
    background: aliceblue !important;
}

.fondo-rojo {
    background: #b4141d !important;
}
</style>