<template>
  <div v-show="!logueado" id="registrarse" class="registrarse container">
    <form @submit.prevent="submit">
      <h2><strong>Crear Cliente</strong></h2>
      <div class="separador">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Tipo de cuenta *</label>
            <select
              class="form-control"
              name="tipo_cuenta"
              v-validate="'required'"
              :class="{ 'is-invalid': submitted && errors.has('tipo_cuenta') }"
              v-model="userData.tipo_de_cuenta_id"
              ref="tipo_de_cuenta_id"
            >
              <option selected="selected" value="1">Personal</option>
              <option value="2">Empresarial</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Primer nombre *</label>
            <input
              name="primer_nombre"
              v-model="userData.primer_nombre"
              v-validate="'required'"
              :class="{
                'is-invalid': submitted && errors.has('primer_nombre'),
              }"
              class="form-control"
              placeholder="Primer nombre *"
              type="text"
            />
            <span v-show="errors.has('primer_nombre')" class="invalid-feedback"
              >Este campo es requerido</span
            >
          </div>
          <div class="form-group col-md-6">
            <label>Segundo nombre</label>
            <input
              name="segundo_nombre"
              v-model="userData.segundo_nombre"
              class="form-control"
              placeholder="Segundo nombre"
              type="text"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Primer apellido *</label>
            <input
              name="primer_apellido"
              v-model="userData.primer_apellido"
              v-validate="'required'"
              :class="{
                'is-invalid': submitted && errors.has('primer_apellido'),
              }"
              class="form-control"
              placeholder="Primer apellido *"
              type="text"
            />
            <span
              v-show="errors.has('primer_apellido')"
              class="invalid-feedback"
              >Este campo es requerido</span
            >
          </div>
          <div class="form-group col-md-6">
            <label>Segundo apellido</label>
            <input
              name="segundo_apellido"
              v-model="userData.segundo_apellido"
              class="form-control"
              placeholder="Segundo apellido"
              type="text"
            />
          </div>
        </div>
        <div class="form-row">
          <div
            class="form-group col-md-6"
            v-if="userData.tipo_de_cuenta_id != '2'"
          >
            <label>Tipo de identificación</label>
            <select
              class="form-control"
              v-model="selectIdentificacion"
              name="eTipoIdentificacion"
            >
              <option v-for="identificacion in tiposDeIdentificacion" v-bind:key="identificacion.id" :value="identificacion.id">
                {{ identificacion.descripcion }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label>Número de identificación</label>
            <input
              name="numero_de_identificacion"
              v-model="userData.numero_de_identificacion"
              class="form-control"
              placeholder="Nit o numero de identificacion"
              type="text"
            />
          </div>
          <div
            class="form-group col-md-6"
            v-if="userData.tipo_de_cuenta_id === '2'"
          >
            <label>Razón social *</label>
            <input
              name="razon_social"
              v-model="userData.razon_social"
              v-validate.immediate="'required_if:tipo_de_cuenta_id,2'"
              :class="{ 'is-invalid': submitted && errors.has('razon_social') }"
              class="form-control"
              placeholder="Razón Social *"
              type="text"
            />
            <span v-show="errors.has('razon_social')" class="invalid-feedback"
              >Este campo es requerido</span
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Email *</label>
            <input
              v-validate="'required'"
              v-model="userData.email"
              name="email"
              type="email"
              :class="{ 'is-danger': errors.has('email') }"
              placeholder="Email"
              ref="email"
              class="form-control"
            />
            <span v-show="errors.has('email')" class="small help text-danger"
              >No es un email válido</span
            >
          </div>
          <div class="form-group col-md-6">
            <label>Confirmación email *</label>
            <input
              v-validate="'required|confirmed:email'"
              v-model="userData.email_confirmation"
              name="email_confirmation"
              type="email"
              :class="{ 'is-danger': errors.has('email_confirmation') }"
              placeholder="Confirmar email"
              data-vv-as="email"
              class="form-control"
            />
            <span
              v-show="errors.has('email_confirmation')"
              class="small help text-danger"
              >El email no coincide</span
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Contraseña *</label>
            <input
              class="form-control"
              v-validate="'required'"
              v-model="userData.password"
              name="password"
              type="password"
              :class="{ 'is-danger': errors.has('password') }"
              placeholder="Contraseña"
              ref="password"
            />
            <span v-show="errors.has('password')" class="small help text-danger"
              >Este campo no puede ser vacio</span
            >
          </div>
          <div class="form-group col-md-6">
            <label>Confirmar contraseña *</label>
            <input
              class="form-control"
              v-validate="'required|confirmed:password'"
              v-model="userData.password_confirmation"
              name="password_confirmation"
              type="password"
              :class="{ 'is-danger': errors.has('password_confirmation') }"
              placeholder="Confirmar contraseña"
              data-vv-as="password"
            />
            <span
              v-show="errors.has('password_confirmation')"
              class="small help text-danger"
              >Las contraseñas no coinciden</span
            >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label class="mt-1 mb-0 ml-1"
              >Fecha de nacimiento (dd/mm/aaaa)</label
            >
            <input
              name="fecha_de_nacimiento"
              v-model="userData.fecha_de_nacimiento"
              class="form-control"
              placeholder="Fecha de nacimiento"
              type="date"
            />
          </div>
          <div class="form-group col-md-6">
            <label class="mt-1 mb-0 ml-1"> Teléfono celular *</label
            ><input
              name="telefono_celular"
              v-model="userData.telefono_celular"
              v-validate="'required'"
              :class="{
                'is-invalid': submitted && errors.has('telefono_celular'),
              }"
              class="form-control"
              placeholder="Teléfono celular *"
              type="text"
            />
            <span
              v-show="errors.has('telefono_celular')"
              class="invalid-feedback"
              >Este campo es requerido</span
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Télefono fijo</label>
            <input
              name="telefono_fijo"
              v-model="userData.telefono_fijo"
              class="form-control"
              placeholder="Teléfono fijo"
              type="text"
            />
          </div>
          <div class="form-group col-md-6">
            <label>Dirección *</label>
            <input
              name="direccion"
              v-model="userData.direccion"
              v-validate="'required'"
              :class="{ 'is-invalid': submitted && errors.has('direccion') }"
              class="form-control"
              placeholder="Dirección*"
              type="text"
            />
            <span v-show="errors.has('direccion')" class="invalid-feedback"
              >Este campo es requerido</span
            >
          </div>
          <div class="form-group col-md-6">
            <label>País *</label>
            <select
              class="form-control"
              v-model="selectPais"
              v-on:change="getEstados(selectPais)"
              name="ePais"
              v-validate="'required'"
              :class="{ 'is-invalid': submitted && errors.has('ePais') }"
            >
              <option v-for="pais in paises" v-bind:key="pais.codigo" :value="pais.codigo">
                {{ pais.nombre }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label>Departamento *</label>
            <select
              class="form-control"
              v-model="selectEstado"
              v-on:change="getCiudades(selectEstado)"
              name="eEstado"
              v-validate="'required'"
              :class="{ 'is-invalid': submitted && errors.has('eEstado') }"
            >
              <option v-for="estado in estados" v-bind:key="estado.id" :value="estado.id">
                {{ estado.nombre }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label>Ciudad *</label>
            <select
              class="form-control"
              v-model="selectCiudad"
              name="eCiudad"
              v-validate="'required'"
              :class="{ 'is-invalid': submitted && errors.has('eCiudad') }"
            >
              <option v-for="ciudad in ciudades" v-bind:key="ciudad.id" :value="ciudad.id">
                {{ ciudad.nombre }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label>Género</label>
            <select
              class="form-control"
              v-model="userData.genero_id"
              name="genero_id"
            >
              <option selected="selected" value="">Género</option>
              <option value="1">Masculino</option>
              <option value="2">Femenino</option>
            </select>
          </div>
          <div
            class="form-group col-md-6"
            v-if="userData.tipo_de_cuenta_id === '2'"
          >
            <label>Página Web</label>
            <input
              name="pagina_web"
              v-model="userData.pagina_web"
              class="form-control"
              placeholder="Página Web"
              type="text"
            />
          </div>
        <p>los campos (*) son obligatorios</p>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4"></div>
          <div class="form-group col-md-4">
            <button type="submit" class="btn btn-dark btn-lg btn-block fondo-rojo" v-bind:disabled="bloquearBoton">Registrar</button>
          </div>
          <div class="form-group col-md-4"></div>
        </div>
      </div>

      <modal v-if="showModal">
        <h3 slot="body" class="modal-body">
          {{ responseMessage }}
        </h3>

        <div slot="footer">
          <button
            type="button"
            c
            class="btn btn-primary"
            data-dismiss="modal"
            @click="closeModal"
          >
            Aceptar
          </button>
        </div>
      </modal>
    </form>
  </div>
</template>

<script>
import Modal from './Modal.vue'
import {valdiarApi} from '../plugins/api_conect'

export default {
  name: "Registrarse",
  components: {
    Modal
  },
  computed: {
    logueado() {
      return this.$store.getters.loggedIn
    }
  },
  methods: {
    clean() {
      this.userData = {
        tipo_de_cuenta_id: 1,
        primer_nombre: "",
        primer_apellido: "",
        segundo_nombre: "",
        segundo_apellido: "",
        tipo_de_identificacion_id: "",
        numero_de_identificacion: "",
        razon_social: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
        fecha_de_nacimiento: "",
        telefono_celular: "",
        telefono_fijo: "",
        direccion: "",
        pais_codigo: "",
        departamento_id: "",
        ciudad_id: "",
        genero_id: "",
        pagina_web: "",
      };
    },
    getTiposIdentificacion() {
      const url = `${process.env.VUE_APP_URL_BACKEND_HELGA}/api/tiposdeidentificacion/${process.env.VUE_APP_ID}`;
        fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.tiposDeIdentificacion = data;

          if (this.tiposDeIdentificacion) {
            this.selectIdentificacion = data.datos[1];
            this.tiposDeIdentificacion = data.datos;
          }
        });
    },
    getPaises() {
      const url = `${process.env.VUE_APP_URL_BACKEND_HELGA}/api/casillero/clientes/getCountries/${process.env.VUE_APP_ID}`;
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.paises = data;

          if (this.pais) {
            const indexPais = this.paises.findIndex(
              (x) => x.codigo === this.pais.codigo
            );
            this.selectPais = this.paises[indexPais].codigo;
            this.getEstados(this.selectPais);
          }
        });
    },
    getEstados(codigoPais) {
      const url = `${process.env.VUE_APP_URL_BACKEND_HELGA}/api/casillero/clientes/getDepartaments/${codigoPais}/${process.env.VUE_APP_ID}`;
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.length > 0) {
            this.estados = data;

            if (this.estado) {
              const indexEstado = this.estados.findIndex(
                (x) => x.id === this.estado.id
              );
              this.selectEstado = this.estados[indexEstado].id;
              this.getCiudades(this.selectEstado);
            }
          } else {
            this.selectEstado = "";
            this.selectCiudad = "";
          }
        });
    },
    getCiudades(idEstado) {
      const url = `${process.env.VUE_APP_URL_BACKEND_HELGA}/api/casillero/clientes/getCities/${idEstado}/${process.env.VUE_APP_ID}`;
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.length > 0) {
            this.ciudades = data;

            if (this.estado) {
              const indexCiudad = this.ciudades.findIndex(
                (x) => x.id === this.ciudad.id
              );
              this.selectCiudad = this.ciudades[indexCiudad].id;
            } 
          } else {
            this.selectCiudad = "";
          }
        });
    },
    closeModal() {
      this.showModal = false;
      this.checked = false;
      this.submitted = false;

      if (this.responseMessage == "Cliente creado exitosamente") {
        this.clean();
        window.location.href = process.env.VUE_APP_URL;
      }
    },
    async enviarDatos(url, datos){
      const response = await fetch(url, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datos),
      }).then((response) => response.json())
      .then(data => data);
      return response;
    },
    armarMensajes(data){
      if (data["code"] == 201) {
        this.mensaje_bien = `Cliente creado exitosamente. \n`
      } else if (data["code"] == 400) {
        this.mensaje_error = `${data["msg"]} ${data["errores"]}. \n`
      } else {
        this.mensaje_error = `${data["msg"]}. \n`
      }
    },
    clearMensajes(){
      this.mensaje_bien = ''
      this.mensaje_error = ''
      this.bloquearBoton = true
    },
    async submit() {
      this.clearMensajes();
      this.submitted = true;

      this.$validator.validate().then(async (valid) => {
        if (valid) {
          this.showModal = true;
          let pais_codigo = '';
          let departamento_id = '';
          let ciudad_id = '';
          let tipo_de_identificacion_id = 1;

          if (this.selectPais) {
            pais_codigo = this.selectPais;
          }
          if (this.selectEstado) {
            departamento_id = this.selectEstado;
          }
          if (this.selectCiudad) {
            ciudad_id = this.selectCiudad;
          }

          if (this.userData["tipo_de_cuenta_id"] == 1) {
            tipo_de_identificacion_id = this.selectIdentificacion;
          } else if (this.userData["tipo_de_cuenta_id"] == 2) {
            tipo_de_identificacion_id = 4;
          }
          let ambientes = valdiarApi();
          let arrayAmbientes = Object.values(ambientes);
          for (let i = 0; i < arrayAmbientes.length; i++) {
            let datos = {};
            datos["tipo_de_cuenta_id"]         = this.userData["tipo_de_cuenta_id"];
            datos["primer_nombre"]             = this.userData["primer_nombre"];
            datos["primer_apellido"]           = this.userData["primer_apellido"];
            datos["segundo_nombre"]            = this.userData["segundo_nombre"];
            datos["segundo_apellido"]          = this.userData["segundo_apellido"];
            datos["tipo_de_identificacion_id"] = this.userData["tipo_de_identificacion_id"];
            datos["numero_de_identificacion"]  = this.userData["numero_de_identificacion"];
            datos["razon_social"]              = this.userData["razon_social"];
            datos["email"]                     = this.userData["email"];
            datos["email_confirmation"]        = this.userData["email_confirmation"];
            datos["password"]                  = this.userData["password"];
            datos["password_confirmation"]     = this.userData["password_confirmation"];
            datos["fecha_de_nacimiento"]       = this.userData["fecha_de_nacimiento"];
            datos["telefono_celular"]          = this.userData["telefono_celular"];
            datos["telefono_fijo"]             = this.userData["telefono_fijo"];
            datos["direccion"]                 = this.userData["direccion"];
            datos["pais_codigo"]               = this.userData["pais_codigo"];
            datos["departamento_id"]           = this.userData["departamento_id"];
            datos["ciudad_id"]                 = this.userData["ciudad_id"];
            datos["genero_id"]                 = this.userData["genero_id"];
            datos["pagina_web"]                = this.userData["pagina_web"];
            datos["pais_codigo"]               = pais_codigo;
            datos["departamento_id"]           = departamento_id;
            datos["ciudad_id"]                 = ciudad_id;
            datos["oficina_id"]                = arrayAmbientes[i].oficina_id;
            datos["servicio_id"]               = arrayAmbientes[i].servicio_id;
            datos["forma_pago_id"]             = arrayAmbientes[i].forma_pago_id;
            datos["tipo_cliente_id"]           = arrayAmbientes[i].tipo_cliente_id;
            datos["medio_pago_id"]             = arrayAmbientes[i].medio_pago_id;
            datos["tipo_de_identificacion_id"] = tipo_de_identificacion_id;
            datos["activo"]                    = true;
            datos["app_id"]                    = `${arrayAmbientes[i].app_id}`;

            const url = `${arrayAmbientes[i].backend_helga}/api/clientes`;
            const response = await this.enviarDatos(url, datos)
            this.armarMensajes(response);

          }
          if (this.mensaje_bien != '' && this.mensaje_error == '') {
            this.$swal('Proceso completado', `${this.mensaje_bien}`, 'success')
            window.location.href = `${process.env.VUE_APP_URL}/`;
          }else if (this.mensaje_bien != '' && this.mensaje_error != ''){
            this.$swal('Proceso completado', `${this.mensaje_bien}`, 'success')
            window.location.href = `${process.env.VUE_APP_URL}/`;
          }else if (this.mensaje_bien == '' && this.mensaje_error != ''){
            this.$swal('Error', `${this.mensaje_error}`, 'error')
            this.bloquearBoton = false
          }
          this.showModal = true;
        }else{
          this.bloquearBoton = false;
        }
      });
    },
  },
  data() {
    return {
      submitted: false,
      showModal: false,
      responseMessage: "",
      checked: false,
      bloquearBoton: false,
      userData: {
        tipo_de_cuenta_id: 1,
        primer_nombre: "",
        primer_apellido: "",
        segundo_nombre: "",
        segundo_apellido: "",
        tipo_de_identificacion_id: "",
        numero_de_identificacion: "",
        razon_social: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
        fecha_de_nacimiento: "",
        telefono_celular: "",
        telefono_fijo: "",
        direccion: "",
        pais_codigo: "",
        departamento_id: "",
        ciudad_id: "",
        genero_id: "",
        pagina_web: "",
        servicio_id: process.env.VUE_APP_SERVICIO_ID,
        forma_pago_id: process.env.VUE_APP_FORMA_PAGO_ID,
        tipo_cliente_id: process.env.VUE_APP_TIPO_CLIENTE_ID,
        medio_pago_id: process.env.VUE_APP_MEDIO_PAGO_ID
      },
      paises: null,
      estados: null,
      ciudades: null,
      selectPais: null,
      selectEstado: null,
      selectCiudad: null,
      pais: null,
      estado: null,
      ciudad: null,
      tiposDeIdentificacion: null,
      selectIdentificacion: null,
      mensaje_error: '',
      mensaje_bien: '',
    };
  },
  mounted() {
    this.getPaises();
    this.getTiposIdentificacion();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #registrarse{
    margin-top: 50px;
    padding: 10px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #b4141d;
  }
  .fondo-rojo {
    background: #b4141d !important;
  }
</style>
