<template>
    <div class="vertical-center recuperarcontrasena">
        <div class="inner-block">
            <div class="vue-tempalte">
                <form @submit.prevent="submit">
                    <h3>Restablecer Contraseña</h3>
    
                    <div class="form-group">
                        <label>Correo electrónico</label>
                        <input class="form-control" v-validate="'required'" v-model="userData.email" name="email" type="email" :class="{ 'is-danger': errors.has('email') }" placeholder="Email" ref="email" />
                        <span v-show="errors.has('email')" class="small help text-danger">No es un email válido</span
                >
              </div>
    
              <button type="submit" class="btn btn-dark btn-block">Enviar</button>
            </form>
          </div>
        </div>
      </div>
</template>

<script>
import { getSelectedEnvironment, api } from '../plugins/api_conect';
export default {
    name: "RecuperarContrasena",
    computed: {
        logueado() {
            return this.$store.getters.loggedIn;
        },
    },
    methods: {
        closeModal() {
            this.showModal = false;
            this.checked = false;
            this.submitted = false;
        },
        submit: function() {
            this.$validator.validate().then((valid) => {
                if (valid) {
                    const currentEnvironment = getSelectedEnvironment();
                    this.submitted = true;
                    this.showModal = true;

                    const datos = {
                        email: this.userData.email,
                        oficina_id: currentEnvironment.oficina_id,
                    };

                    api().post("/api/usuarios/password/email", datos, {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                            },
                        })
                        .then((response) => {
                            const { data } = response;
                            if (response.status === 200) {
                                this.$swal("Proceso completado", data["msg"], "success");
                                this.showModal = true;
                                this.checked = true;
                                window.location.href = `${process.env.VUE_APP_URL}/`;
                            }
                        }).catch((error) => {
                            if (error.response && error.response.status === 404) {
                                this.$swal(
                                    "Error",
                                    error.response.data.msg,
                                    "error"
                                );
                                this.checked = true;
                            }
                            if (error.response && error.response.status != 404) {
                                this.$swal(
                                    "Error",
                                    "Error no se pudo enviar el correo " + error.response.data.msg,
                                    "error"
                                );
                                this.checked = true;
                            }
                        });
                }
            });
        },
    },
    data() {
        return {
            submitted: false,
            showModal: false,
            checked: false,
            responseMessage: "",
            userData: {
                email: "",
            },
        };
    },
};
</script>

<style scoped>
.recuperarcontrasena {
    min-height: 80vh;
    display: flex;
    font-weight: 400;
}

body,
html,
.App,
.vue-tempalte,
.vertical-center {
    width: 100%;
    height: 100%;
}

.vertical-center {
    display: flex;
    text-align: left;
    justify-content: center;
    flex-direction: column;
}

.inner-block {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
}

@media (max-width: 768px) {
    .inner-block {
        width: auto;
    }
}

.vertical-center .form-control:focus {
    border-color: #b4141d;
    box-shadow: none;
}

.vertical-center h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
}

label {
    font-weight: 500;
}
</style>