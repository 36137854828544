<template>
  <div id="rastrearpedido" class="container">
    <div class="panel panel-default">
      <div class="panel-heading font-bold">
        <h2>Rastrea tu pedido</h2>  
      </div>
      <form id="frmConsultaEstado" class="form-horizontal" @submit.prevent="submit">                                  
        <div class="panel-body separador">
          <div class="form-group">
            <div class="col-sm-offset-2 col-sm-10">
              <span class="text-xs text-white app-form__required help-block m-b-none">* Campos requeridos.</span>
            </div>
          </div>
        
          <div class="form-group">
            <label class="col-sm-12">Buscar envios por: Número de sello / Referencia / Guía de transportadora / Tracking Casillero *</label>
            <div class="col-sm-12">
              <input class="form-control text-uppercase" id="referencia" name="referencia" type="text" v-model="referencia" required="">
            </div>
            <p v-if="errors.length">
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </p> 
          </div>
          <div class="form-group col-sm-12" v-if="ambiente && !logueado ">
            <label for="">Bodega - ORLANDO</label>
            <!-- <select
              class="form-control col-sm-3"
              v-validate="{ required: ambiente && !logueado }"
              name="selbodega"
              id="selbodega"
              @change="changeBodega()"
              v-model="bodega"
            >
              <option
                v-for="item in bodegaOptions"
                :key="item.text"
                :value="item.value"
                >{{ item.text }}</option
              >
            </select> -->
          </div>
        </div>
        <br>
          <div class="form-group m-b-xs m-t-xs">
            <div class="col-sm-12 text-right">
              <button @click="consultarEstado" type="button" class="btn btn-dark btn-addon fondo-rojo"><b-icon icon="search" aria-hidden="true"></b-icon> Consultar</button>
            </div>
          </div>
      </form>                   
      <div id="tabla" class="separador" style="display: none">         
        <div class="table-responsive app-table">
          <table class="table table-striped b-t table-color">
            <thead class="app-bkg-color-f5">
              <tr>
                <th>Sello</th>
                <th>Referencia</th>
                <th>Fecha / Hora</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="ng-binding">{{sello}}</td>
                <td class="ng-binding">{{referencia2}}</td>
                <td class="ng-binding" v-if="seguimiento.length > 0">{{seguimiento[0]['fecha']}}</td>
                <td class="ng-binding" v-else></td>
                <td class="ng-binding">{{estadoEnvio}}</td>
              </tr>
              <tr class="app-bkg-color-f5"> <td></td><td></td><td></td><td></td>
              </tr>
            </tbody>
          </table>
          <br>
          <table class="table table-striped b-t table-color">
            <tbody>
              <tr>
                <th>No de Sello</th>
                <td class="ng-binding">{{sello}}</td>
              </tr>
              <tr>
                <th>Guía transportadora</th>
                <td class="ng-binding">{{guiaServientrega}}</td>
              </tr>
              <tr>
                <th>Estado</th>
                <td class="ng-binding">{{estadoEnvio}}</td>
              </tr>
              <tr>
                <th>Destino final</th>
                <td class="ng-binding">{{destino}}</td>
              </tr>
              <tr>
                <th>Peso + Peso volumen</th>
                <td class="ng-binding">{{peso}} + {{peso_volumen}}</td>
              </tr>
              <tr>
                <th>Novedad</th>
                <td class="ng-binding">{{novedad}}</td>
              </tr>
            </tbody>
          </table>
          <br>
          <table class="table table-striped b-t table-color">
            <thead class="app-bkg-color-f5">
              <tr>
                <th>Fecha / Hora</th>
                <th>Seguimiento</th>
                <th>Observación</th>
              </tr>
            </thead>
        
            <tbody>
              <tr class="ng-scope" style="" v-for="seg in seguimiento" v-bind:key="seg.id">
                <td class="ng-binding">{{seg['fecha']}}</td>
                <td class="ng-binding">{{seg['estadoAlt']}}</td>
                <td class="ng-binding">{{seg['observacion']}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>               
    </div>
  </div> 
</template>

<script>
import { getSelectedEnvironment, api, showBodega } from "../plugins/api_conect";
export default {
  methods: {
    consultarEstado: function () {
      this.errors = [];

      if (this.referencia === "") {
        this.errors.push("Este campo es requerido.");
      } else if (this.bodega === "" && this.ambiente && !this.logueado) {
        this.errors.push("La bodega es requerida.");
      } else {
        const currentEnvironment = getSelectedEnvironment();
        api()
          .get(
            `/api/consulta-estado/${this.referencia}/${currentEnvironment.app_id}`
          )
          .then((response) => {
            const { data } = response;
            (this.destino = data.datos["Destino"]),
              (this.estadoEnvio = data.datos["Estado_Envio"]),
              (this.fecha_Entrega = data.datos["Fecha_Entrega"]),
              (this.guiaServientrega = data.datos["Guia_Servientrega"]),
              (this.novedad = data.datos["Novedad"]),
              (this.peso = data.datos["Peso"]),
              (this.peso_volumen = data.datos["Peso_volumen"]),
              (this.referencia2 = data.datos["Referencia"]),
              (this.seguimiento = data.datos["Seguimiento"]),
              (this.sello = data.datos["Sello"]),
              (this.descripcion_transportadora =
                data.datos["Descripcion_transportadora"]),
              (this.url_transportadora = data.datos["url_transportadora"]);

            let div = document.getElementById("tabla");
            div.style.display = "block";
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$swal("Error", error.response.data.msg, "error");
            } else {
              this.$swal("Error", "Error", "error");
            }
          });
      }
    },
    changeBodega() {
      localStorage.bodega = this.bodega;
    },
  },
  data() {
    return {
      errors: [],
      referencia: "",
      destino: null,
      estadoEnvio: null,
      fecha_Entrega: null,
      guiaServientrega: null,
      novedad: null,
      peso: null,
      peso_volumen: null,
      referencia2: null,
      seguimiento: [],
      sello: null,
      descripcion_transportadora: null,
      url_transportadora: null,
      ambiente: showBodega(),
      bodega: 'orlando',
      bodegaOptions: [
        { text: "ORLANDO", value: "orlando" },
      ],
    };
  },
  computed: {
    logueado() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#rastrearpedido {
  margin-top: 50px;
  padding: 10px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #b4141d;
}
.table-color {
  color: black !important;
  background: aliceblue !important;
}
.fondo-rojo {
  background: #b4141d !important;
}
</style>